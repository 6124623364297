<template>
  <div>
    <two-panel
      :left-panel-width="'820'"
      :right-panel-width="'567'"
      :left-panel-title="leftPanelTitle"
      :right-panel-title="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :table-height="'603'"
          :headers="headers"
          :items="subscriptions"
          :now-per-page="nowPerPage"
          :loading="isTableOverlay"
          :custom-slot-info="customSlotInfo"
          :search-opts="searchSubscription"
          :total-records="totalRecords"
          :total-pages="totalPages"
          :reload-toggle="reloadToggle"
          @rowClick="rowClick"
          @getDataFromApi="getDataFromApi"
        >
          <template #searchOptions>
            <label for="search_srvcId" class="sort-label">
              · {{ $t('service.subscription.search-condition1') }}
            </label>
            <select
              class="sort-input-text"
              style="width: 160px"
              v-model="searchSubscription.srvcId"
            >
              <option value="">{{
                $t('common.select', { target: '항목' })
              }}</option>
              <option
                v-for="(srvc, index) in srvcs"
                :key="index"
                :value="srvc.srvcId"
              >
                {{ srvc.srvcNm }}
              </option>
            </select>
          </template>
          <template #subHeaderItem>
            <input
              type="button"
              class="bt-line24 cr-orange"
              :value="btnRegister"
              @click="doOpenInsertPopUp()"
            />
          </template>
          <template #phrServiceSubscs_custom="{ item }">
            <td
              class="text-center td-ellipsis"
              :title="getSubConditionByList(item.phrServiceSubscs)"
            >
              {{ getSubConditionByList(item.phrServiceSubscs) }}
            </td>
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :table-height="'710'"
          :tab-overlay="isTabOverlay"
          :tab-items="tabItems"
        >
          <template #phrServiceSubscs_tab_custom="{item}">
            <td class="sub__condition__tab" style="padding: 0px !important;">
              <div style="height: 158px">
                <table class="table-style-default">
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="12%" />
                    <col width="13%" />
                    <col width="13%" />
                    <col width="22%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('service.subscription.rightPanel.subHeader1') }}
                      </th>
                      <th>세부항목</th>
                      <th>유형</th>
                      <th>최소 값</th>
                      <th>최대 값</th>
                      <th>
                        {{ $t('service.subscription.rightPanel.subHeader2') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(subscription, index) in item.value"
                      :key="index"
                    >
                      <td>{{ subscription.itemNm }}</td>
                      <td>{{ subscription.itemDetailNm }}</td>
                      <td>{{ subscription.scopeCodeDc }}</td>
                      <td>{{ subscription.minValue }}</td>
                      <td>{{ subscription.maxValue }}</td>
                      <td>{{ subscription.expDt }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </template>

          <template #subscriptionInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="doOpenUpdatePopUp()"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="doDeleteSubscription()"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <subscription-pop-up
      ref="insertSubscriptionPopUp"
      :is-open="isOpenInsertPopUp"
      :pop-up-mode="popUpMode"
      @close="doClosePopUp"
      :subscription-info="insertSubscriptionInfo"
      @addRow="addRow"
      @deleteRow="deleteRow"
      @changeCodeSystem="changeCodeSystem"
      :srvcs="srvcs"
      @confirm="doConfirmPopUp"
      :fhir-codes-by-code-system="fhirCodesByCodeSystem"
      @changeUpperMesureCodeSn="changeUpperMesureCodeSn"
      :common-scope-codes="commonScopeCodes"
    ></subscription-pop-up>

    <subscription-pop-up
      ref="updateSubscriptionPopUp"
      :is-open="isOpenUpdatePopUp"
      :pop-up-mode="popUpMode"
      @close="doClosePopUp"
      :subscription-info="updateSubscriptionInfo"
      @addRow="addRow"
      @deleteRow="deleteRow"
      @changeCodeSystem="changeCodeSystem"
      :srvcs="srvcs"
      @confirm="doConfirmPopUp"
      :fhir-codes-by-code-system="fhirCodesByCodeSystem"
      @changeUpperMesureCodeSn="changeUpperMesureCodeSn"
      :common-scope-codes="commonScopeCodes"
    ></subscription-pop-up>
  </div>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import {
  getSubscriptions,
  deleteSubscription,
  insertSubscription,
  updateSubscription,
} from '@/api/serviceApi';
import CommonMixins from '@/mixins/CommonMixins';
import SubscriptionPopUp from '@/views/service/subscription/SubscriptionPopUp.vue';

import { getFHIRCodes, getCommonCode } from '@/api/commonApi';
import { getOperatorSrvcList } from '@/api/operationApi';
import { COMMON_GROUP_CODE } from '@/store/properties';

const INSERT = 'insert';
const UPDATE = 'update';
const KOSTOM = 'KOSTOM';
const SNOMED_CT = 'SNOMED CT';
const LOINC = 'LOINC';
const KOSTOM_VALUE = 'kostom';
const SNOMED_CT_VALUE = 'snomed_ct';
const LOINC_VALUE = 'loinc';
const KOSTOM_SYSTEM_URL = 'https://www.hins.or.kr';
const SNOMED_CT_SYSTEM_URL = 'http://snomed.info/sct';
const LOINC_SYSTEM_URL = 'http://loinc.org';

export default {
  data() {
    return {
      // custom 슬롯
      customSlotInfo: [
        {
          name: 'phrServiceSubscs',
          slotName: 'item.phrServiceSubscs',
        },
      ],
      // subscription 목록
      subscriptions: [],
      // 30페이지씩
      nowPerPage: 30,
      // table 로딩
      isTableOverlay: false,
      // 리로드
      reloadToggle: false,
      // 총 수
      totalRecords: 0,
      // 총 페이지 수
      totalPages: 0,
      // 서비스 찾기
      searchSubscription: {
        srvcId: '',
      },
      // 클릭한 Row 정보
      clickedRow: {},
      tabItems: [],
      isTabOverlay: false,
      popUpMode: '',
      isOpenInsertPopUp: false,
      insertSubscriptionInfo: this.initInsertSubscriptionInfo(),
      isOpenUpdatePopUp: false,
      updateSubscriptionInfo: this.initUpdateSubscriptionInfo(),
      fhirCodesByCodeSystem: [],
      alreadySubscriptionSrvcIds: [],
      srvcs: [],
      fhirCodes: [],
      commonScopeCodes: [],
    };
  },
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    SubscriptionPopUp,
  },
  mixins: [CommonMixins],
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  methods: {
    // 공통 범위 코드 목록 조회
    async doGetCommonScopeCodes() {
      try {
        const { data } = await getCommonCode(
          COMMON_GROUP_CODE.CODE_SUBSCRIPTION_COMMON_SCOPE_CODE,
        );
        this.commonScopeCodes = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    // FHIR 코드 목록 조회
    async doGetFHIRCodes() {
      try {
        const { data } = await getFHIRCodes();
        this.fhirCodes = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    // 서비스 목록 조회
    async doGetOperatorSrvcs() {
      try {
        const { data } = await getOperatorSrvcList();
        this.srvcs = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    // 구독 등록 초기화
    initInsertSubscriptionInfo() {
      return {
        srvcId: '',
        codeSystem: '',
        phrServiceSubscs: [this.initPhrServiceSubscs()],
        pushRedirectUrl: '',
      };
    },
    // 구독 수정 초기화
    initUpdateSubscriptionInfo() {
      return {
        srvcId: '',
        codeSystem: '',
        phrServiceSubscs: [],
        pushRedirectUrl: '',
      };
    },
    // phrServiceSubscs 초기화
    initPhrServiceSubscs() {
      return {
        upperMesureCodeSn: '',
        mesureCodes: [],
        mesureCodeSn: '',
        scopeCode: '',
        minValue: '',
        maxValue: '',
        expDt: '',
      };
    },
    // Subscription 목록 조회
    async doGetSubscriptions(searchParams) {
      this.doResetClicked();
      const { page, rows, sidx, sord, srvcId } = searchParams;

      const reqObj = {
        page,
        rows,
        sidx,
        sord,
        srvcId,
      };

      try {
        const { data } = await getSubscriptions(reqObj);
        const { rows: items, totalPages, totalRecords } = data;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
        this.subscriptions = items;
        this.alreadySubscriptionSrvcIds = items.map(item => item.srvcId);
      } catch (error) {
        console.log('error', error);
      }
    },
    async getDataFromApi(searchParams) {
      this.isTableOverlay = true;
      await this.doGetFHIRCodes();
      await this.doGetOperatorSrvcs();
      await this.doGetCommonScopeCodes();
      await this.doGetSubscriptions(searchParams);
      this.isTableOverlay = false;
    },
    // 목록에서 구독 조건 조회
    getSubConditionByList(phrServiceSubscs) {
      let subConditionStr;

      if (phrServiceSubscs.length > 0) {
        let resultMesureCodeNms = [];
        phrServiceSubscs.forEach(phrServiceSubsc => {
          const { mesureCodeSn } = phrServiceSubsc;
          const resultFHIRCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);

          const resultMesureCodeNm = resultFHIRCode.mesureCodeNm;
          resultMesureCodeNms.push(resultMesureCodeNm);
        });

        subConditionStr = resultMesureCodeNms.join(', ');
      } else {
        subConditionStr = '-';
      }

      return subConditionStr;
    },
    // mesureCodeSn에 따른 fhirCode 반환
    getFHIRCodeByMesureCodeSn(phrSubscriptionMesureCodeSn) {
      let resultFHIRCode;

      for (let i in this.fhirCodes) {
        const fhirCode = this.fhirCodes[i];
        const { mesureCodeSn, children } = fhirCode;
        if (phrSubscriptionMesureCodeSn === mesureCodeSn) {
          resultFHIRCode = fhirCode;
          break;
        } else {
          for (let k in children) {
            const child = children[k];
            const { mesureCodeSn: childMesureCodeSn } = child;
            if (phrSubscriptionMesureCodeSn === childMesureCodeSn) {
              resultFHIRCode = child;
              break;
            }
          }
        }
      }

      return resultFHIRCode;
    },
    // upperMesureCode에 따른 부모 fhirCode 반환
    getParentsFHIRCodeByUpperMesureCode(childUpperMesureCode) {
      let resultFHIRCode;

      for (let i in this.fhirCodes) {
        let fhirCode = this.fhirCodes[i];
        const { mesureCode } = fhirCode;
        if (childUpperMesureCode === mesureCode) {
          resultFHIRCode = fhirCode;
          break;
        }
      }

      return resultFHIRCode;
    },
    // scopeCode에 따른 commonScopeCode 반환
    getCommonScopeCodeByScopeCode(scopeCode) {
      let resultCommonScopeCode;

      for (let i in this.commonScopeCodes) {
        let commonScopeCode = this.commonScopeCodes[i];
        const { codeId } = commonScopeCode;
        if (scopeCode === codeId) {
          resultCommonScopeCode = commonScopeCode;
        }
      }

      return resultCommonScopeCode;
    },
    // 검색
    doSearch() {
      this.$refs.tableListComponent.search();
    },
    // row 클릭
    rowClick(item) {
      this.clickedRow = item;
      this.doGetSubscriptionDetail();
    },
    getConditionsForDetail(phrServiceSubscs) {
      let resultConditions = [];

      if (phrServiceSubscs.length > 0) {
        phrServiceSubscs.forEach(phrServiceSubsc => {
          const {
            scopeCode,
            minValue,
            maxValue,
            expDt,
            mesureCodeSn,
          } = phrServiceSubsc;

          let fhirCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);
          const { upperMesureCode, mesureCodeNm } = fhirCode;

          let upperMesureCodeNm;
          let childMesureCodeNm;
          if (upperMesureCode === null) {
            upperMesureCodeNm = mesureCodeNm;
            childMesureCodeNm = '-';
          } else {
            const parentsFHIRCode = this.getParentsFHIRCodeByUpperMesureCode(
              upperMesureCode,
            );
            upperMesureCodeNm = parentsFHIRCode.mesureCodeNm;
            childMesureCodeNm = mesureCodeNm;
          }

          let scopeCodeDc;
          if (scopeCode) {
            let commonScopeCode = this.getCommonScopeCodeByScopeCode(scopeCode);
            scopeCodeDc = commonScopeCode.codeName;
          } else {
            scopeCodeDc = '-';
          }

          resultConditions.push({
            itemNm: upperMesureCodeNm,
            itemDetailNm: childMesureCodeNm,
            scopeCodeDc: scopeCodeDc,
            minValue,
            maxValue,
            expDt,
          });
        });
      }

      return resultConditions;
    },
    getCodeSystemDisplayByPhrServiceSubscs(phrServiceSubscs) {
      let codeSystemDisplay;

      if (phrServiceSubscs.length > 0) {
        const mesureCodeSn = phrServiceSubscs[0].mesureCodeSn;
        const fhirCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);
        const { codeSysUri } = fhirCode;
        if (codeSysUri === KOSTOM_SYSTEM_URL) {
          codeSystemDisplay = KOSTOM;
        } else if (codeSysUri === SNOMED_CT_SYSTEM_URL) {
          codeSystemDisplay = SNOMED_CT;
        } else if (codeSysUri === LOINC_SYSTEM_URL) {
          codeSystemDisplay = LOINC;
        }
      } else {
        codeSystemDisplay = '-';
      }

      return codeSystemDisplay;
    },
    // subscription 상세 조회
    doGetSubscriptionDetail() {
      this.isTabOverlay = true;

      const subscriptionInfoTabItems = this.tabItems[0].selectedItem;
      const { srvcId, phrServiceSubscs, pushRedirectUrl } = this.clickedRow;
      let codeSystemDisplay = this.getCodeSystemDisplayByPhrServiceSubscs(
        phrServiceSubscs,
      );
      let conditions = this.getConditionsForDetail(phrServiceSubscs);

      subscriptionInfoTabItems.srvcId.value = srvcId;
      subscriptionInfoTabItems.codeSystem.value = codeSystemDisplay;
      subscriptionInfoTabItems.phrServiceSubscs.value = conditions;
      subscriptionInfoTabItems.pushRedirectUrl.value = pushRedirectUrl;

      this.isTabOverlay = false;
    },
    // click 리셋
    doResetClicked() {
      this.doResetTab();
      this.clickedRow = {};
    },
    // tab 리셋
    doResetTab() {
      const subscriptionInfoTabItems = this.tabItems[0].selectedItem;
      const keys = Object.keys(subscriptionInfoTabItems);
      keys.forEach(key => {
        const item = subscriptionInfoTabItems[key];
        if (key === 'phrServiceSubscs') {
          item.value = [];
        } else {
          item.value = '';
        }
      });
    },
    // 구독 삭제
    async doDeleteSubscription() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.subscription.target-subscription'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      const { srvcId, phrServiceSubscs } = this.clickedRow;

      const confirmMessage = `<p>${this.$i18n.t(
        'service.subscription.target-serviceId',
      )} : <span class="c-orange">${srvcId}</span></p>${this.$i18n.t(
        'common.alert.delete-confirm',
      )}`;
      const { ok } = await this.$dialogs.confirm(confirmMessage);

      if (ok) {
        if (phrServiceSubscs.length < 1) {
          this.$dialogs.alert('삭제할 구독이 없습니다.');

          return false;
        }

        try {
          this.isTabOverlay = true;
          const { data } = await deleteSubscription(srvcId);
          const { valid, message } = data;
          if (valid) {
            this.$dialogs.alert(message).then(() => {
              this.reloadToggle = !this.reloadToggle;
            });
          }
        } catch (error) {
          console.log('error', error);
        } finally {
          this.isTabOverlay = false;
        }
      }
    },
    // 구독 등록 팝업 오픈
    doOpenInsertPopUp() {
      this.isOpenInsertPopUp = true;
      this.popUpMode = INSERT;
    },
    // 용어체계에 따라 fhirCode 다르게
    getFHIRCodesByCodeSystem(codeSystem) {
      let fhirCodes;

      if (codeSystem) {
        if (codeSystem === KOSTOM_VALUE) {
          fhirCodes = this.fhirCodes.filter(fhirCode => {
            const { codeSysUri } = fhirCode;
            return codeSysUri === KOSTOM_SYSTEM_URL;
          });
        } else if (codeSystem === SNOMED_CT_VALUE) {
          fhirCodes = this.fhirCodes.filter(fhirCode => {
            const { codeSysUri } = fhirCode;
            return codeSysUri === SNOMED_CT_SYSTEM_URL;
          });
        } else if (codeSystem === LOINC_VALUE) {
          fhirCodes = this.fhirCodes.filter(fhirCode => {
            const { codeSysUri } = fhirCode;
            return codeSysUri === LOINC_SYSTEM_URL;
          });
        }
      } else {
        fhirCodes = [];
      }

      return fhirCodes;
    },
    // 코드 시스템 변경
    changeCodeSystem(popUpMode) {
      if (popUpMode === INSERT) {
        this.insertSubscriptionInfo.phrServiceSubscs = [
          this.initPhrServiceSubscs(),
        ];
        const insertCodeSystem = this.insertSubscriptionInfo.codeSystem;
        this.fhirCodesByCodeSystem = this.getFHIRCodesByCodeSystem(
          insertCodeSystem,
        );
      } else if (popUpMode === UPDATE) {
        this.updateSubscriptionInfo.phrServiceSubscs = [
          this.initPhrServiceSubscs(),
        ];
        const updateCodeSystem = this.updateSubscriptionInfo.codeSystem;
        this.fhirCodesByCodeSystem = this.getFHIRCodesByCodeSystem(
          updateCodeSystem,
        );
      }
    },
    getMatchFHIRCodeByUpperMesureCodeSn(upperMesureCodeSn) {
      let matchFHIRCode;

      for (let i in this.fhirCodes) {
        const fhirCode = this.fhirCodes[i];
        if (upperMesureCodeSn === fhirCode.mesureCodeSn) {
          matchFHIRCode = fhirCode;
          break;
        }
      }

      return matchFHIRCode;
    },
    // 상위 코드 변경할 때
    changeUpperMesureCodeSn(popUpMode, i) {
      if (popUpMode === INSERT) {
        let upperMesureCodeSn = this.insertSubscriptionInfo.phrServiceSubscs[i]
          .upperMesureCodeSn;

        let matchFHIRCode = this.getMatchFHIRCodeByUpperMesureCodeSn(
          upperMesureCodeSn,
        );

        if (matchFHIRCode) {
          if (matchFHIRCode.children.length > 0) {
            this.insertSubscriptionInfo.phrServiceSubscs[i].mesureCodes =
              matchFHIRCode.children;
            this.insertSubscriptionInfo.phrServiceSubscs[i].mesureCodeSn = '';
          } else {
            this.insertSubscriptionInfo.phrServiceSubscs[i].mesureCodes = [];
            this.insertSubscriptionInfo.phrServiceSubscs[i].mesureCodeSn = '';
          }
        } else {
          this.insertSubscriptionInfo.phrServiceSubscs[i].mesureCodes = [];
          this.insertSubscriptionInfo.phrServiceSubscs[i].mesureCodeSn = '';
        }
      } else if (popUpMode === UPDATE) {
        let upperMesureCodeSn = this.updateSubscriptionInfo.phrServiceSubscs[i]
          .upperMesureCodeSn;

        let matchFHIRCode = this.getMatchFHIRCodeByUpperMesureCodeSn(
          upperMesureCodeSn,
        );

        if (matchFHIRCode) {
          if (matchFHIRCode.children.length > 0) {
            this.updateSubscriptionInfo.phrServiceSubscs[i].mesureCodes =
              matchFHIRCode.children;
            this.updateSubscriptionInfo.phrServiceSubscs[i].mesureCodeSn = '';
          } else {
            this.updateSubscriptionInfo.phrServiceSubscs[i].mesureCodes = [];
            this.updateSubscriptionInfo.phrServiceSubscs[i].mesureCodeSn = '';
          }
        } else {
          this.updateSubscriptionInfo.phrServiceSubscs[i].mesureCodes = [];
          this.updateSubscriptionInfo.phrServiceSubscs[i].mesureCodeSn = '';
        }
      }
    },
    getConditionForOpenUpdate(phrServiceSubscs) {
      let updatePhrServiceSubscs = [];

      if (phrServiceSubscs.length > 0) {
        phrServiceSubscs.forEach(phrServiceSubsc => {
          const {
            mesureCodeSn,
            scopeCode,
            minValue,
            maxValue,
            expDt,
          } = phrServiceSubsc;

          let upperMesureCodeSn;
          let childMesureCodeSn;
          let childMesureCodes;

          const fhirCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);
          const {
            upperMesureCode,
            mesureCodeSn: fhirCodeMesureCodeSn,
            children,
          } = fhirCode;
          if (upperMesureCode === null) {
            upperMesureCodeSn = fhirCodeMesureCodeSn;
            childMesureCodeSn = '';
            childMesureCodes = children;
          } else {
            const parentsFHIRCode = this.getParentsFHIRCodeByUpperMesureCode(
              upperMesureCode,
            );
            upperMesureCodeSn = parentsFHIRCode.mesureCodeSn;
            childMesureCodeSn = fhirCodeMesureCodeSn;
            childMesureCodes = parentsFHIRCode.children;
          }

          updatePhrServiceSubscs.push({
            upperMesureCodeSn: upperMesureCodeSn,
            mesureCodeSn: childMesureCodeSn,
            mesureCodes: childMesureCodes,
            scopeCode: scopeCode ? scopeCode : '',
            minValue: minValue ? minValue : null,
            maxValue: maxValue ? maxValue : null,
            expDt: expDt,
          });
        });
      } else {
        updatePhrServiceSubscs.push(this.initPhrServiceSubscs());
      }

      return updatePhrServiceSubscs;
    },
    getCodeSystemByPhrServiceSubscs(phrServiceSubscs) {
      let codeSystem;

      if (phrServiceSubscs.length > 0) {
        const mesureCodeSn = phrServiceSubscs[0].mesureCodeSn;
        const fhirCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);
        const { codeSysUri } = fhirCode;
        if (codeSysUri === KOSTOM_SYSTEM_URL) {
          codeSystem = KOSTOM_VALUE;
        } else if (codeSysUri === SNOMED_CT_SYSTEM_URL) {
          codeSystem = SNOMED_CT_VALUE;
        } else if (codeSysUri === LOINC_SYSTEM_URL) {
          codeSystem = LOINC_VALUE;
        }
      } else {
        codeSystem = '';
      }

      return codeSystem;
    },
    // 수정 화면
    doOpenUpdatePopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.subscription.target-subscription'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      const { srvcId, phrServiceSubscs, pushRedirectUrl } = this.clickedRow;

      let updatePhrServiceSubscs = this.getConditionForOpenUpdate(
        phrServiceSubscs,
      );
      let updateCodeSystem = this.getCodeSystemByPhrServiceSubscs(
        phrServiceSubscs,
      );

      this.updateSubscriptionInfo.srvcId = srvcId;
      this.updateSubscriptionInfo.codeSystem = updateCodeSystem;
      this.changeCodeSystem(UPDATE);
      this.updateSubscriptionInfo.phrServiceSubscs = updatePhrServiceSubscs;
      this.updateSubscriptionInfo.pushRedirectUrl = pushRedirectUrl;

      this.isOpenUpdatePopUp = true;
      this.popUpMode = UPDATE;
    },
    // 팝업 닫았을 시
    doClosePopUp(popUpMode) {
      if (popUpMode === INSERT) {
        this.isOpenInsertPopUp = false;
        this.fhirCodesByCodeSystem = [];
        this.$refs.insertSubscriptionPopUp.doReset();
        this.insertSubscriptionInfo = this.initInsertSubscriptionInfo();
      } else {
        this.isOpenUpdatePopUp = false;
        this.fhirCodesByCodeSystem = [];
        this.$refs.updateSubscriptionPopUp.doReset();
        this.updateSubscriptionInfo = this.initUpdateSubscriptionInfo();
      }
    },
    // add 버튼 클릭 시
    addRow(popUpMode) {
      if (popUpMode === INSERT) {
        this.insertSubscriptionInfo.phrServiceSubscs.push(
          this.initPhrServiceSubscs(),
        );
      } else {
        this.updateSubscriptionInfo.phrServiceSubscs.push(
          this.initPhrServiceSubscs(),
        );
      }
    },
    // delete 버튼 클릭 시
    deleteRow(index, popUpMode) {
      if (popUpMode === INSERT) {
        this.insertSubscriptionInfo.phrServiceSubscs.splice(index, 1);
      } else {
        this.updateSubscriptionInfo.phrServiceSubscs.splice(index, 1);
      }
    },
    // phrServiceSubscs 정리
    managePhrServiceSubscs(srvcId, phrServiceSubscs) {
      let reqPhrServiceSubscs = [];

      phrServiceSubscs.forEach(phrServiceSubsc => {
        const {
          upperMesureCodeSn,
          mesureCodeSn,
          scopeCode,
          minValue,
          maxValue,
          expDt,
        } = phrServiceSubsc;

        let reqPhrServiceSubsc = {
          srvcId,
          mesureCodeSn: mesureCodeSn ? mesureCodeSn : upperMesureCodeSn,
          scopeCode: scopeCode ? scopeCode : null,
          minValue: minValue ? minValue : null,
          maxValue: maxValue ? maxValue : null,
          expDt,
        };
        reqPhrServiceSubscs.push(reqPhrServiceSubsc);
      });

      return reqPhrServiceSubscs;
    },
    // 구독 등록
    async doInsertSubscription() {
      const {
        srvcId,
        phrServiceSubscs,
        pushRedirectUrl,
      } = this.insertSubscriptionInfo;

      if (this.alreadySubscriptionSrvcIds.includes(srvcId)) {
        this.$dialogs.alert('이미 구독 목록에 있는 서비스입니다.');
        return false;
      }

      const reqObj = {
        srvcId,
        phrServiceSubscs: this.managePhrServiceSubscs(srvcId, phrServiceSubscs),
        pushRedirectUrl,
      };

      try {
        const { data } = await insertSubscription(reqObj);
        const { valid, message } = data;
        if (valid) {
          await this.$dialogs.alert(message);
          this.doClosePopUp(INSERT);
          this.reloadToggle = !this.reloadToggle;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async doUpdateSubscription() {
      const {
        srvcId,
        phrServiceSubscs,
        pushRedirectUrl,
      } = this.updateSubscriptionInfo;

      const reqObj = {
        srvcId,
        phrServiceSubscs: this.managePhrServiceSubscs(srvcId, phrServiceSubscs),
        pushRedirectUrl,
      };

      try {
        const { data } = await updateSubscription(reqObj);
        const { valid, message } = data;
        if (valid) {
          await this.$dialogs.alert(message);
          this.doClosePopUp(UPDATE);
          this.reloadToggle = !this.reloadToggle;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    // 확인 버튼 클릭 시
    doConfirmPopUp(popUpMode) {
      if (popUpMode === INSERT) {
        this.doInsertSubscription();
      } else {
        this.doUpdateSubscription();
      }
    },
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('service.subscription.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.subscription.leftPanel.table-column1'),
          value: 'srvcId',
          width: '30%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.subscription.leftPanel.table-column2'),
          value: 'phrServiceSubscs',
          width: '30%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.subscription.leftPanel.table-column3'),
          value: 'pushRedirectUrl',
          width: '40%',
          sortable: false,
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.subscription.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'subscriptionInfo',
          headers: [
            {
              text: this.$i18n.t('service.subscription.rightPanel.header1'),
              width: '30%',
            },
            {
              text: this.$i18n.t('service.subscription.rightPanel.header2'),
              width: '70%',
            },
          ],
          selectedItem: {
            srvcId: {
              name: this.$i18n.t('service.subscription.rightPanel.label1'),
              value: '',
            },
            codeSystem: {
              name: '용어체계',
              value: '',
            },
            phrServiceSubscs: {
              name: this.$i18n.t('service.subscription.rightPanel.label2'),
              value: [],
              isCustom: true,
            },
            pushRedirectUrl: {
              name: this.$i18n.t('service.subscription.rightPanel.label3'),
              value: '',
            },
          },
        },
      ];
    },
    lang() {
      return this.$i18n.locale;
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
      this.getCommonCode();
    },
  },
};
</script>

<style scoped>
.bt-line24 {
  margin: 1.5px;
}
.sub__condition__tab {
  overflow: auto;
}

.sub__condition__tab table {
  border-top: 1px solid #ccc;
}

.sub__condition__tab thead th {
  background: #f6f6f6 !important;
  text-align: left;
}
</style>
