<template>
  <div>
    <two-panel
      :leftPanelWidth="'820'"
      :rightPanelWidth="'567'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :tableHeight="'603'"
          :headers="headers"
          :nowPerPage="nowPerPage"
          :items="alarmRuleList"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :customSlotInfo="customSlotInfo"
          :searchOpts="searchAlarmRule"
          :loading="isTableOverlay"
          :reloadToggle="reloadToggle"
          @rowClick="rowClick"
          @getDataFromApi="getDataFromApi"
        >
          <template #searchOptions>
            <!-- <label for="search_alarmRuleId" class="sort-label">
              · {{ $t('service.rule.search-condition1') }}
            </label>
            <input
              type="text"
              id="search_alarmRuleId"
              class="sort-input-text"
              v-model="searchAlarmRule.alarmRuleId"
              maxlength="20"
              @keydown.enter="doSearch()"
            /> -->
            <!-- <label for="search_alarmItemCode" class="sort-label">
              · {{ $t('service.rule.search-condition2') }}
            </label>
            <select
              id="search_alarmItemCode"
              class="sort-input-text "
              style="width:110px;"
              v-model="searchAlarmRule.alarmItemCode"
            >
              <option value="">{{
                $t('common.select', { target: '항목' })
              }}</option>
              <option
                v-for="ruleAlarmItem in ruleAlarmItems"
                :key="ruleAlarmItem.code"
                :value="ruleAlarmItem.code"
              >
                {{ ruleAlarmItem.name }}
              </option>
            </select> -->
            <label for="search_alarmRuleId" class="sort-label">
              · {{ $t('service.rule.search-condition1') }}
            </label>
            <input
              type="text"
              id="search_alarmRuleId"
              class="sort-input-text"
              v-model="searchAlarmRule.alarmRuleId"
              maxlength="20"
              @keydown.enter="doSearch()"
            />
            <label for="search_crtrId" class="sort-label">
              · {{ $t('service.rule.search-condition3') }}
            </label>
            <input
              type="text"
              id="search_crtrId"
              class="sort-input-text"
              v-model="searchAlarmRule.crtrId"
              maxlength="20"
              @keydown.enter="doSearch()"
            />
          </template>
          <template #subHeaderItem>
            <input
              type="button"
              class="bt-line24 cr-orange"
              :value="btnRegister"
              @click="isOpenInsertPopUp = true"
            />
          </template>
          <template v-slot:alarmRuleNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.alarmRuleNm">
              {{ item.alarmRuleNm }}
            </td>
          </template>
          <template v-slot:creatDt_custom="{ item }">
            <td class="text-center">
              {{ item.creatDt !== '-' ? getFormatDate(item.creatDt) : '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :tableHeight="'710'"
          :tabOverlay="isTabOverlay"
          :tabItems="tabItems"
        >
          <template #alarmRule_tab_custom="{item}">
            <td class="alarm-rule-tab" style="padding: 0px !important;">
              <div style="height: 158px">
                <table class="table-style-default">
                  <colgroup>
                    <col width="18%" />
                    <col width="25%" />
                    <col width="25%" />
                    <col width="14%" />
                    <col width="18%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ $t('service.rule.rightPanel.subHeader1') }}</th>
                      <th>세부항목</th>
                      <th>{{ $t('service.rule.rightPanel.subHeader2') }}</th>
                      <th>{{ $t('service.rule.rightPanel.subHeader3') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rule, i) in item.value" :key="i">
                      <td>
                        {{
                          `${$t('service.rule.rightPanel.label14')} ${i + 1}`
                        }}
                      </td>
                      <td>
                        {{ rule.itemNm }}
                      </td>
                      <td>
                        {{ rule.itemDetailNm }}
                      </td>
                      <td>
                        {{ rule.scopeCodeDc }}
                      </td>
                      <td>
                        {{ rule.ruleValue }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </template>

          <template #alarmMsgCn_tab_custom="{item}">
            <td>
              <div style="height: 50px; overflow-y:auto;">
                <span>
                  {{ item.value ? item.value : '-' }}
                </span>
              </div>
            </td>
          </template>

          <template #creatDt_tab_custom="{item}">
            <td>
              {{
                item.value && item.value !== '-'
                  ? getFormatDate(item.value)
                  : '-'
              }}
            </td>
          </template>

          <template #updtDt_tab_custom="{item}">
            <td>
              {{
                item.value && item.value !== '-'
                  ? getFormatDate(item.value)
                  : '-'
              }}
            </td>
          </template>

          <template #subscriptions_tab_custom="{item}">
            <td class="sub__condition__tab" style="padding: 0px !important;">
              <div style="height: 132px">
                <table class="table-style-default">
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="12%" />
                    <col width="13%" />
                    <col width="13%" />
                    <col width="22%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('service.subscription.rightPanel.subHeader1') }}
                      </th>
                      <th>세부항목</th>
                      <th>유형</th>
                      <th>최소 값</th>
                      <th>최대 값</th>
                      <th>
                        {{ $t('service.subscription.rightPanel.subHeader2') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(subscription, i) in item.value" :key="i">
                      <td>{{ subscription.itemNm }}</td>
                      <td>{{ subscription.itemDetailNm }}</td>
                      <td>{{ subscription.scopeCodeDc }}</td>
                      <td>{{ subscription.minValue }}</td>
                      <td>{{ subscription.maxValue }}</td>
                      <td>{{ subscription.expDt }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </template>

          <template #alarmRuleInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="openUpdatePopUp()"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="doDeleteRule()"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <rule-pop-up
      ref="insertRulePopUp"
      :type="'insert'"
      :isOpen="isOpenInsertPopUp"
      :alarmRuleInfo="insertAlarmRuleInfo"
      :srvcList="srvcList"
      @close="closeInsertPopUp"
      @confirm="doInsertRule"
      :fhir-codes="fhirCodes"
      @changeCodeSystem="changeCodeSystem"
      :fhir-codes-by-code-system="fhirCodesByCodeSystem"
      @changeUpperMesureCodeSn="changeUpperMesureCodeSn"
      :rule-common-scope-codes="ruleCommonScopeCodes"
      @addRow="addRow"
      @deleteRow="deleteRow"
    ></rule-pop-up>

    <rule-pop-up
      ref="updateRulePopUp"
      :type="'update'"
      :isOpen="isOpenUpdatePopUp"
      :alarmRuleInfo="updateAlarmRuleInfo"
      :srvcList="srvcList"
      @close="closeUpdatePopUp"
      @confirm="doUpdateRule"
      :fhir-codes="fhirCodes"
      @changeCodeSystem="changeCodeSystem"
      :fhir-codes-by-code-system="fhirCodesByCodeSystem"
      @changeUpperMesureCodeSn="changeUpperMesureCodeSn"
      :rule-common-scope-codes="ruleCommonScopeCodes"
      @addRow="addRow"
      @deleteRow="deleteRow"
    ></rule-pop-up>
  </div>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import RulePopUp from '@/views/service/rule/RulePopUp.vue';
import {
  getRuleList,
  insertRule,
  updateRule,
  deleteRule,
  getSubscriptionBySrvcId,
} from '@/api/serviceApi';
import CommonMixins from '@/mixins/CommonMixins';
import { getCommonCode, getFHIRCodes } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';
import { getOperatorSrvcList } from '@/api/operationApi';

const INSERT = 'insert';
const UPDATE = 'update';
const KOSTOM = 'KOSTOM';
const SNOMED_CT = 'SNOMED CT';
const LOINC = 'LOINC';
const KOSTOM_VALUE = 'kostom';
const SNOMED_CT_VALUE = 'snomed_ct';
const LOINC_VALUE = 'loinc';
const KOSTOM_SYSTEM_URL = 'https://www.hins.or.kr';
const SNOMED_CT_SYSTEM_URL = 'http://snomed.info/sct';
const LOINC_SYSTEM_URL = 'http://loinc.org';

export default {
  data() {
    return {
      customSlotInfo: [
        {
          name: 'alarmRuleNm',
          slotName: 'item.alarmRuleNm',
        },
        {
          name: 'creatDt',
          slotName: 'item.creatDt',
        },
      ],
      nowPerPage: 30,
      alarmRuleList: [],
      totalRecords: 0,
      totalPages: 0,
      searchAlarmRule: {
        alarmRuleId: '',
        crtrId: '',
      },
      isTableOverlay: false,
      reloadToggle: true,
      isTabOverlay: false,
      tabItems: [],
      clickedRow: {},
      isOpenInsertPopUp: false,
      insertAlarmRuleInfo: this.initInsertAlarmRuleInfo(),
      isOpenUpdatePopUp: false,
      updateAlarmRuleInfo: this.initUpdateAlarmRuleInfo(),
      srvcList: [],
      fhirCodes: [],
      fhirCodesByCodeSystem: [],
      subscriptionCommonScopeCodes: [],
      ruleCommonScopeCodes: [],
    };
  },
  mixins: [CommonMixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    RulePopUp,
  },
  created() {
    this.tabItems = this.tabItemsComputed;
    this.doGetOperatorSrvcList();
  },
  methods: {
    // 알람 등록 정보 초기화
    initInsertAlarmRuleInfo() {
      return {
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        codeSystem: '',
        ruleList: [
          {
            upperMesureCodeSn: '',
            mesureCodes: [],
            mesureCodeSn: '',
            scopeCode: '',
            ruleValue: '',
          },
        ],
        genderCode: 'C',
        alarmTyCode: 'and',
        alarmCycleValue: 0,
        alarmMsgCn: '',
      };
    },
    // 알람 수정 정보 초기화
    initUpdateAlarmRuleInfo() {
      return {
        alarmRuleSn: 0,
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        codeSystem: '',
        ruleList: [],
        genderCode: '',
        alarmTyCode: '',
        alarmCycleValue: 0,
        alarmMsgCn: '',
      };
    },
    // 알람 조건 초기화
    initRuleList() {
      return {
        upperMesureCodeSn: '',
        mesureCodes: [],
        mesureCodeSn: '',
        scopeCode: '',
        ruleValue: '',
      };
    },
    // 조건 row 추가
    addRow(popUpMode) {
      if (popUpMode === INSERT) {
        this.insertAlarmRuleInfo.ruleList.push(this.initRuleList());
      } else if (popUpMode === UPDATE) {
        this.updateAlarmRuleInfo.ruleList.push(this.initRuleList());
      }
    },
    // 조건 row 삭제
    deleteRow(i, popUpMode) {
      if (popUpMode === INSERT) {
        this.insertAlarmRuleInfo.ruleList.splice(i, 1);
      } else if (popUpMode === UPDATE) {
        this.updateAlarmRuleInfo.ruleList.splice(i, 1);
      }
    },
    // KOSTOM CODE인지 확인
    getIsKostomCodeByMesureCode(mesureCode) {
      return mesureCode.indexOf('H') > -1;
    },
    // upperMesureCodeSn으로 맞는 FHIRCode 조회
    getMatchFHIRCodeByUpperMesureCodeSn(upperMesureCodeSn) {
      let matchFHIRCode;

      for (let i in this.fhirCodes) {
        const fhirCode = this.fhirCodes[i];
        if (upperMesureCodeSn === fhirCode.mesureCodeSn) {
          matchFHIRCode = fhirCode;
          break;
        }
      }

      return matchFHIRCode;
    },
    // upperMesureCodeSn 변경
    changeUpperMesureCodeSn(popUpMode, i) {
      if (popUpMode === INSERT) {
        let upperMesureCodeSn = this.insertAlarmRuleInfo.ruleList[i]
          .upperMesureCodeSn;

        let matchFHIRCode = this.getMatchFHIRCodeByUpperMesureCodeSn(
          upperMesureCodeSn,
        );

        if (matchFHIRCode) {
          if (matchFHIRCode.children.length > 0) {
            this.insertAlarmRuleInfo.ruleList[i].mesureCodes =
              matchFHIRCode.children;
            this.insertAlarmRuleInfo.ruleList[i].mesureCodeSn = '';
          } else {
            this.insertAlarmRuleInfo.ruleList[i].mesureCodes = [];
            this.insertAlarmRuleInfo.ruleList[i].mesureCodeSn = '';
          }
        } else {
          this.insertAlarmRuleInfo.ruleList[i].mesureCodes = [];
          this.insertAlarmRuleInfo.ruleList[i].mesureCodeSn = '';
        }
      } else if (popUpMode === UPDATE) {
        let upperMesureCodeSn = this.updateAlarmRuleInfo.ruleList[i]
          .upperMesureCodeSn;

        let matchFHIRCode = this.getMatchFHIRCodeByUpperMesureCodeSn(
          upperMesureCodeSn,
        );

        if (matchFHIRCode) {
          if (matchFHIRCode.children.length > 0) {
            this.updateAlarmRuleInfo.ruleList[i].mesureCodes =
              matchFHIRCode.children;
            this.updateAlarmRuleInfo.ruleList[i].mesureCodeSn = '';
          } else {
            this.updateAlarmRuleInfo.ruleList[i].mesureCodes = [];
            this.updateAlarmRuleInfo.ruleList[i].mesureCodeSn = '';
          }
        } else {
          this.updateAlarmRuleInfo.ruleList[i].mesureCodes = [];
          this.updateAlarmRuleInfo.ruleList[i].mesureCodeSn = '';
        }
      }
    },
    // 용어체계에 따라 fhirCode 다르게
    getFHIRCodesByCodeSystem(codeSystem) {
      let fhirCodes;

      if (codeSystem) {
        if (codeSystem === KOSTOM_VALUE) {
          fhirCodes = this.fhirCodes.filter(fhirCode => {
            const { codeSysUri } = fhirCode;
            return codeSysUri === KOSTOM_SYSTEM_URL;
          });
        } else if (codeSystem === SNOMED_CT_VALUE) {
          fhirCodes = this.fhirCodes.filter(fhirCode => {
            const { codeSysUri } = fhirCode;
            return codeSysUri === SNOMED_CT_SYSTEM_URL;
          });
        } else if (codeSystem === LOINC_VALUE) {
          fhirCodes = this.fhirCodes.filter(fhirCode => {
            const { codeSysUri } = fhirCode;
            return codeSysUri === LOINC_SYSTEM_URL;
          });
        }
      } else {
        fhirCodes = [];
      }

      return fhirCodes;
    },
    // 용어체계 변경
    changeCodeSystem(popUpMode) {
      if (popUpMode === INSERT) {
        this.insertAlarmRuleInfo.ruleList = [this.initRuleList()];
        const insertCodeSystem = this.insertAlarmRuleInfo.codeSystem;
        this.fhirCodesByCodeSystem = this.getFHIRCodesByCodeSystem(
          insertCodeSystem,
        );
      } else if (popUpMode === UPDATE) {
        this.updateAlarmRuleInfo.ruleList = [this.initRuleList()];
        const updateCodeSystem = this.updateAlarmRuleInfo.codeSystem;
        this.fhirCodesByCodeSystem = this.getFHIRCodesByCodeSystem(
          updateCodeSystem,
        );
      }
    },
    // FHIR 코드 목록 조회
    async doGetFHIRCodes() {
      try {
        const { data } = await getFHIRCodes();
        this.fhirCodes = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    // 서비스 목록 조회
    async doGetOperatorSrvcList() {
      try {
        const { data: srvcList } = await getOperatorSrvcList();
        this.srvcList = srvcList;
      } catch (error) {
        console.log('error', error);
      }
    },
    // 구독 공통 범위 코드 유형 목록 조회
    async doGetSubscriptionCommonScopeCodes() {
      try {
        const { data } = await getCommonCode(
          COMMON_GROUP_CODE.CODE_SUBSCRIPTION_COMMON_SCOPE_CODE,
        );
        this.subscriptionCommonScopeCodes = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    // 알람 조건 공통 범위 코드 목록 조회
    async doGetRuleCommonScopeCodes() {
      try {
        const { data } = await getCommonCode(
          COMMON_GROUP_CODE.CODE_RULE_COMMON_SCOPE_CODE,
        );
        this.ruleCommonScopeCodes = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    // 알람 목록 조회
    async doGetRuleList(searchParams) {
      this.doResetClicked();

      const { page, rows, sidx, sord, alarmRuleId, crtrId } = searchParams;

      const reqObj = {
        page,
        rows,
        sidx,
        sord,
        alarmRuleId: alarmRuleId.trim(),
        crtrId: crtrId.trim(),
      };

      try {
        const { data } = await getRuleList(reqObj);
        const { rows: items, totalPages, totalRecords } = data;
        this.alarmRuleList = items;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
      } catch (error) {
        console.log('error', error);
      }
    },
    async getDataFromApi(searchParams) {
      this.isTableOverlay = true;
      await this.doGetFHIRCodes();
      await this.doGetRuleCommonScopeCodes();
      await this.doGetSubscriptionCommonScopeCodes();
      await this.doGetRuleList(searchParams);
      this.isTableOverlay = false;
    },
    rowClick(item) {
      this.clickedRow = item;
      this.doGetRuleDetail();
    },
    getRulesDisplayByRuleDtlVOList(ruleDtlVOList) {
      let ruleList = [];

      for (let i in ruleDtlVOList) {
        const ruleDtlVO = ruleDtlVOList[i];
        const { alarmItemCode, scopeCode, ruleValue } = ruleDtlVO;
        if (alarmItemCode === 'gender') {
          continue;
        }
        let fhirCode = this.getFHIRCodeByMesureCode(alarmItemCode);
        const { upperMesureCode, mesureCodeNm } = fhirCode;
        let itemNm;
        let itemDetailNm;
        if (upperMesureCode) {
          let parentsFHIRCode = this.getParentsFHIRCodeByUpperMesureCode(
            upperMesureCode,
          );
          itemNm = parentsFHIRCode.mesureCodeNm;
          itemDetailNm = mesureCodeNm;
        } else {
          itemNm = mesureCodeNm;
          itemDetailNm = '-';
        }

        let ruleCommonScopeCode = this.getRuleCommonScopeCodeByScopeCode(
          scopeCode,
        );

        ruleList.push({
          itemNm,
          itemDetailNm,
          scopeCodeDc: ruleCommonScopeCode.codeName,
          ruleValue,
        });
      }

      return ruleList;
    },
    getRuleCommonScopeCodeByScopeCode(scopeCode) {
      let resultRuleCommonScopeCode;

      for (let i in this.ruleCommonScopeCodes) {
        let ruleCommonScopeCode = this.ruleCommonScopeCodes[i];
        const { codeId } = ruleCommonScopeCode;
        if (scopeCode === codeId) {
          resultRuleCommonScopeCode = ruleCommonScopeCode;
          break;
        }
      }

      return resultRuleCommonScopeCode;
    },
    getGenderDisplayByRuleDtlVOList(ruleDtlVOList) {
      let genderDisplay;

      for (let i in ruleDtlVOList) {
        const ruleDtlVO = ruleDtlVOList[i];
        const { alarmItemCode, ruleValue } = ruleDtlVO;
        if (alarmItemCode === 'gender') {
          genderDisplay = ruleValue === 'M' ? '남성' : '여성';
          break;
        }
      }

      return genderDisplay ? genderDisplay : '공통';
    },
    getCodeSystemDisplayByRuleDtlVOList(ruleDtlVOList) {
      let codeSystemDisplay;

      if (ruleDtlVOList.length > 0) {
        let alarmItemCode;
        const firstRuleDtl = ruleDtlVOList[0];
        if (firstRuleDtl.alarmItemCode !== 'gender') {
          alarmItemCode = firstRuleDtl.alarmItemCode;
        } else {
          if (ruleDtlVOList.length > 1) {
            const secondRuleDtl = ruleDtlVOList[1];
            alarmItemCode = secondRuleDtl.alarmItemCode;
          }
        }

        const fhirCode = this.getFHIRCodeByMesureCode(alarmItemCode);
        const { codeSysUri } = fhirCode;
        if (codeSysUri === KOSTOM_SYSTEM_URL) {
          codeSystemDisplay = KOSTOM;
        } else if (codeSysUri === SNOMED_CT_SYSTEM_URL) {
          codeSystemDisplay = SNOMED_CT;
        } else if (codeSysUri === LOINC_SYSTEM_URL) {
          codeSystemDisplay = LOINC;
        }
      } else {
        codeSystemDisplay = '-';
      }

      return codeSystemDisplay;
    },
    async doGetRuleDetail() {
      this.isTabOverlay = true;
      const alarmRuleInfoTabItems = this.tabItems[0].selectedItem;

      const {
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleDtlVOList,
        alarmTyCode,
        ruleTyCode,
        alarmCycleValue,
        alarmMsgCn,
        crtrId,
        creatDt,
        updusrId,
        updtDt,
      } = this.clickedRow;

      alarmRuleInfoTabItems.alarmRuleId.value = alarmRuleId;
      alarmRuleInfoTabItems.alarmRuleNm.value = alarmRuleNm;
      alarmRuleInfoTabItems.srvcId.value = srvcId;
      alarmRuleInfoTabItems.alarmTyCode.value =
        alarmTyCode === '&&'
          ? this.$i18n.t('service.rule.popUp.alarmType-label1')
          : this.$i18n.t('service.rule.popUp.alarmType-label2');
      alarmRuleInfoTabItems.ruleTyCode.value =
        ruleTyCode === 'ALARM' ? '알람' : '피드';
      alarmRuleInfoTabItems.alarmCycleValue.value = alarmCycleValue;
      alarmRuleInfoTabItems.alarmMsgCn.value = alarmMsgCn;
      alarmRuleInfoTabItems.crtrId.value = crtrId;
      alarmRuleInfoTabItems.creatDt.value = creatDt;
      alarmRuleInfoTabItems.updusrId.value = updusrId;
      alarmRuleInfoTabItems.updtDt.value = updtDt;
      alarmRuleInfoTabItems.codeSystem.value = this.getCodeSystemDisplayByRuleDtlVOList(
        ruleDtlVOList,
      );
      alarmRuleInfoTabItems.alarmRule.value = this.getRulesDisplayByRuleDtlVOList(
        ruleDtlVOList,
      );
      alarmRuleInfoTabItems.genderCode.value = this.getGenderDisplayByRuleDtlVOList(
        ruleDtlVOList,
      );

      const phrServiceSubscs = await this.doGetSubscriptionBySrvcId(srvcId);
      alarmRuleInfoTabItems.subscriptions.value = this.getSubscriptionsDisplayByPhrServiceSubscs(
        phrServiceSubscs,
      );
      this.isTabOverlay = false;
    },
    getSubscriptionsDisplayByPhrServiceSubscs(phrServiceSubscs) {
      let subscriptionsDisplay = [];

      if (phrServiceSubscs.length > 0) {
        phrServiceSubscs.forEach(phrServiceSubsc => {
          const {
            mesureCodeSn,
            scopeCode,
            minValue,
            maxValue,
            expDt,
          } = phrServiceSubsc;

          const fhirCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);
          const { upperMesureCode, mesureCodeNm } = fhirCode;
          let itemNm;
          let itemDetailNm;
          let itemScopeCodeDc;
          let itemMinValue;
          let itemMaxValue;
          if (upperMesureCode) {
            const parentsFHIRCode = this.getParentsFHIRCodeByUpperMesureCode(
              upperMesureCode,
            );
            const { mesureCodeNm: parentsMesureCodeNm } = parentsFHIRCode;
            itemNm = parentsMesureCodeNm;
            itemDetailNm = mesureCodeNm;
          } else {
            itemNm = mesureCodeNm;
            itemDetailNm = '-';
          }

          if (scopeCode) {
            const subscriptionCommonScopeCode = this.getSubscriptionCommonScopeCodeByScopeCode(
              scopeCode,
            );
            itemScopeCodeDc = subscriptionCommonScopeCode.codeName;
          } else {
            itemScopeCodeDc = '-';
          }

          if (minValue) {
            itemMinValue = minValue;
          } else {
            itemMinValue = '-';
          }

          if (maxValue) {
            itemMaxValue = maxValue;
          } else {
            itemMaxValue = '-';
          }

          subscriptionsDisplay.push({
            itemNm,
            itemDetailNm,
            scopeCodeDc: itemScopeCodeDc,
            minValue: itemMinValue,
            maxValue: itemMaxValue,
            expDt,
          });
        });
      }

      return subscriptionsDisplay;
    },
    getSubscriptionCommonScopeCodeByScopeCode(scopeCode) {
      let resultSubscriptionCommonScopeCode;

      for (let i in this.subscriptionCommonScopeCodes) {
        const subscriptionCommonScopeCode = this.subscriptionCommonScopeCodes[
          i
        ];
        const { codeId } = subscriptionCommonScopeCode;
        if (scopeCode === codeId) {
          resultSubscriptionCommonScopeCode = subscriptionCommonScopeCode;
          break;
        }
      }

      return resultSubscriptionCommonScopeCode;
    },
    async doGetSubscriptionBySrvcId(srvcId) {
      try {
        const { data } = await getSubscriptionBySrvcId(srvcId);
        return data.phrServiceSubscs;
      } catch (error) {
        console.log('error', error);
      }
    },
    doSearch() {
      this.$refs.tableListComponent.search();
    },
    doResetClicked() {
      this.doResetTab();
      this.clickedRow = {};
    },
    doResetTab() {
      const alarmRuleInfoTabItems = this.tabItems[0].selectedItem;
      const keys = Object.keys(alarmRuleInfoTabItems);
      keys.forEach(key => {
        const item = alarmRuleInfoTabItems[key];
        if (key === 'alarmRule' || key === 'subscriptions') {
          item.value = [];
        } else {
          item.value = '';
        }
      });
    },
    closeInsertPopUp() {
      this.isOpenInsertPopUp = false;
      this.fhirCodesByCodeSystem = [];
      this.$refs.insertRulePopUp.doReset();
      this.insertAlarmRuleInfo = this.initInsertAlarmRuleInfo();
    },
    // mesureCodeSn에 따른 fhirCode 반환
    getFHIRCodeByMesureCodeSn(phrSubscriptionMesureCodeSn) {
      let resultFHIRCode;

      for (let i in this.fhirCodes) {
        const fhirCode = this.fhirCodes[i];
        const { mesureCodeSn, children } = fhirCode;
        if (phrSubscriptionMesureCodeSn === mesureCodeSn) {
          resultFHIRCode = fhirCode;
          break;
        } else {
          for (let k in children) {
            const child = children[k];
            const { mesureCodeSn: childMesureCodeSn } = child;
            if (phrSubscriptionMesureCodeSn === childMesureCodeSn) {
              resultFHIRCode = child;
              break;
            }
          }
        }
      }

      return resultFHIRCode;
    },
    // mesureCode에 따른 fhirCode 반환
    getFHIRCodeByMesureCode(ruleMesureCode) {
      let resultFHIRCode;

      for (let i in this.fhirCodes) {
        const fhirCode = this.fhirCodes[i];
        const { mesureCode, children } = fhirCode;
        if (ruleMesureCode === mesureCode) {
          resultFHIRCode = fhirCode;
          break;
        } else {
          if (children.length > 0) {
            for (let k in children) {
              const child = children[k];
              const { mesureCode: childMesureCode } = child;
              if (ruleMesureCode === childMesureCode) {
                resultFHIRCode = child;
                break;
              }
            }
          }
        }
      }

      return resultFHIRCode;
    },
    // upperMesureCode에 따른 부모 fhirCode 반환
    getParentsFHIRCodeByUpperMesureCode(childUpperMesureCode) {
      let resultFHIRCode;

      for (let i in this.fhirCodes) {
        let fhirCode = this.fhirCodes[i];
        const { mesureCode } = fhirCode;
        if (childUpperMesureCode === mesureCode) {
          resultFHIRCode = fhirCode;
          break;
        }
      }

      return resultFHIRCode;
    },
    getRuleDtlVOListByRuleList(genderCode, ruleList) {
      let ruleDtlVOList = [];

      ruleList.forEach(rule => {
        const { upperMesureCodeSn, mesureCodeSn, scopeCode, ruleValue } = rule;

        let mesureCode;
        if (mesureCodeSn) {
          const fhirCode = this.getFHIRCodeByMesureCodeSn(mesureCodeSn);
          const childMesureCode = fhirCode.mesureCode;
          mesureCode = childMesureCode;
        } else {
          const parentsFHIRCode = this.getFHIRCodeByMesureCodeSn(
            upperMesureCodeSn,
          );
          const parentsMesureCode = parentsFHIRCode.mesureCode;
          mesureCode = parentsMesureCode;
        }

        ruleDtlVOList.push({
          alarmItemCode: mesureCode,
          scopeCode,
          ruleValue,
        });
      });

      if (genderCode === 'M') {
        ruleDtlVOList.push({
          alarmItemCode: 'gender',
          scopeCode: '1',
          ruleValue: genderCode,
        });
      } else if (genderCode === 'F') {
        ruleDtlVOList.push({
          alarmItemCode: 'gender',
          scopeCode: '1',
          ruleValue: genderCode,
        });
      }

      return ruleDtlVOList;
    },
    async doInsertRule() {
      const {
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleList,
        genderCode,
        alarmTyCode,
        alarmCycleValue,
        alarmMsgCn,
      } = this.insertAlarmRuleInfo;

      const reqObj = {
        alarmRuleId: alarmRuleId.trim(),
        srvcId,
        alarmRuleNm: alarmRuleNm.trim(),
        alarmTyCode: alarmTyCode === 'and' ? '&&' : '||',
        ruleTyCode: 'ALARM',
        alarmMsgCn: alarmMsgCn.trim(),
        alarmCycleValue: Number(alarmCycleValue),
        ruleDtlVOList: this.getRuleDtlVOListByRuleList(genderCode, ruleList),
      };

      try {
        const { data } = await insertRule(reqObj);
        const { valid } = data;
        if (valid) {
          await this.$dialogs.alert(
            this.$i18n.t('common.alert.insert-success'),
          );
          this.closeInsertPopUp();
          this.reloadToggle = !this.reloadToggle;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    getCodeSystemByRuleDtlVOList(ruleDtlVOList) {
      let resultCodeSystem;

      if (ruleDtlVOList.length > 0) {
        let alarmItemCode;
        const firstRuleDtl = ruleDtlVOList[0];
        if (firstRuleDtl.alarmItemCode !== 'gender') {
          alarmItemCode = firstRuleDtl.alarmItemCode;
        } else {
          if (ruleDtlVOList.length > 1) {
            const secondRuleDtl = ruleDtlVOList[1];
            alarmItemCode = secondRuleDtl.alarmItemCode;
          }
        }

        const fhirCode = this.getFHIRCodeByMesureCode(alarmItemCode);
        const { codeSysUri } = fhirCode;

        if (codeSysUri === KOSTOM_SYSTEM_URL) {
          resultCodeSystem = KOSTOM_VALUE;
        } else if (codeSysUri === SNOMED_CT_SYSTEM_URL) {
          resultCodeSystem = SNOMED_CT_VALUE;
        } else if (codeSysUri === LOINC_SYSTEM_URL) {
          resultCodeSystem = LOINC_VALUE;
        }
      } else {
        resultCodeSystem = '';
      }

      return resultCodeSystem;
    },
    getRulesByRuleDtlVOList(ruleDtlVOList) {
      let resultRules = [];

      if (ruleDtlVOList.length > 0) {
        for (let i in ruleDtlVOList) {
          let resultUpperMesureCodeSn;
          let resultMesureCodeSn;
          let resultMesureCodes;

          const ruleDtlVO = ruleDtlVOList[i];
          const { alarmItemCode, scopeCode, ruleValue } = ruleDtlVO;
          if (alarmItemCode === 'gender') {
            continue;
          } else {
            const fhirCode = this.getFHIRCodeByMesureCode(alarmItemCode);
            const { upperMesureCode, mesureCodeSn, children } = fhirCode;
            if (upperMesureCode) {
              const parentsFHIRCode = this.getParentsFHIRCodeByUpperMesureCode(
                upperMesureCode,
              );
              resultUpperMesureCodeSn = parentsFHIRCode.mesureCodeSn;
              resultMesureCodeSn = mesureCodeSn;
              resultMesureCodes = parentsFHIRCode.children;
            } else {
              resultUpperMesureCodeSn = mesureCodeSn;
              resultMesureCodeSn = '';
              resultMesureCodes = children;
            }
          }

          resultRules.push({
            upperMesureCodeSn: resultUpperMesureCodeSn,
            mesureCodeSn: resultMesureCodeSn,
            mesureCodes: resultMesureCodes,
            scopeCode,
            ruleValue,
          });
        }
      } else {
        resultRules.push(this.initRuleList());
      }

      return resultRules;
    },
    getGenderCodeByRuleDtlVOList(ruleDtlVOList) {
      let genderCode;

      if (ruleDtlVOList.length > 0) {
        for (let i in ruleDtlVOList) {
          let ruleDtlVO = ruleDtlVOList[i];
          const { alarmItemCode, ruleValue } = ruleDtlVO;
          if (alarmItemCode === 'gender') {
            genderCode = ruleValue;
            break;
          }
        }
      } else {
        genderCode = 'C';
      }

      return genderCode ? genderCode : 'C';
    },
    openUpdatePopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.rule.target-rule'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      const {
        alarmRuleSn,
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleDtlVOList,
        alarmTyCode,
        alarmCycleValue,
        alarmMsgCn,
      } = this.clickedRow;

      this.updateAlarmRuleInfo.alarmRuleSn = alarmRuleSn;
      this.updateAlarmRuleInfo.alarmRuleId = alarmRuleId;
      this.updateAlarmRuleInfo.alarmRuleNm = alarmRuleNm;
      this.updateAlarmRuleInfo.srvcId = srvcId;
      this.updateAlarmRuleInfo.codeSystem = this.getCodeSystemByRuleDtlVOList(
        ruleDtlVOList,
      );
      this.changeCodeSystem(UPDATE);
      this.updateAlarmRuleInfo.alarmTyCode =
        alarmTyCode === '&&' ? 'and' : 'or';
      this.updateAlarmRuleInfo.alarmCycleValue = alarmCycleValue;
      this.updateAlarmRuleInfo.alarmMsgCn = alarmMsgCn;
      this.updateAlarmRuleInfo.ruleList = this.getRulesByRuleDtlVOList(
        ruleDtlVOList,
      );
      this.updateAlarmRuleInfo.genderCode = this.getGenderCodeByRuleDtlVOList(
        ruleDtlVOList,
      );

      this.isOpenUpdatePopUp = true;
    },
    async doUpdateRule() {
      const {
        alarmRuleSn,
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleList,
        genderCode,
        alarmTyCode,
        alarmCycleValue,
        alarmMsgCn,
      } = this.updateAlarmRuleInfo;

      const reqObj = {
        alarmRuleSn,
        alarmRuleId,
        alarmRuleNm: alarmRuleNm.trim(),
        srvcId,
        alarmTyCode: alarmTyCode === 'and' ? '&&' : '||',
        ruleTyCode: 'ALARM',
        alarmCycleValue: Number(alarmCycleValue),
        alarmMsgCn: alarmMsgCn.trim(),
        ruleDtlVOList: this.getRuleDtlVOListByRuleList(genderCode, ruleList),
      };

      try {
        const { data } = await updateRule(reqObj);
        const { valid } = data;
        if (valid) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.update-success'))
            .then(() => {
              this.closeUpdatePopUp();
              this.reloadToggle = !this.reloadToggle;
            });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    closeUpdatePopUp() {
      this.isOpenUpdatePopUp = false;
      this.fhirCodesByCodeSystem = [];
      this.$refs.updateRulePopUp.doReset();
      this.updateAlarmRuleInfo = this.initUpdateAlarmRuleInfo();
    },
    async doDeleteRule() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.rule.target-rule'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      const { alarmRuleSn, alarmRuleId } = this.clickedRow;

      const confirmText = `<p>Alarm Rule ID: <span class="c-orange">${alarmRuleId}</span></p>${this.$i18n.t(
        'common.alert.delete-confirm',
      )}`;
      const { ok } = await this.$dialogs.confirm(confirmText);
      if (ok) {
        try {
          const { data } = await deleteRule(alarmRuleSn);
          const { valid } = data;
          if (valid) {
            this.$dialogs
              .alert(this.$i18n.t('common.alert.delete-success'))
              .then(() => {
                this.reloadToggle = !this.reloadToggle;
              });
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    },
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('service.rule.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column1'),
          value: 'alarmRuleId',
          width: '20%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column2'),
          value: 'alarmRuleNm',
          width: '26%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column3'),
          value: 'srvcId',
          width: '17%',
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column4'),
          value: 'creatDt',
          width: '22%',
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column5'),
          value: 'crtrId',
          width: '15%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.rule.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'alarmRuleInfo',
          headers: [
            {
              text: this.$i18n.t('service.rule.rightPanel.header1'),
              width: '30%',
            },
            {
              text: this.$i18n.t('service.rule.rightPanel.header2'),
              width: '70%',
            },
          ],
          selectedItem: {
            alarmRuleId: {
              name: this.$i18n.t('service.rule.rightPanel.label1'),
              value: '',
            },
            alarmRuleNm: {
              name: this.$i18n.t('service.rule.rightPanel.label2'),
              value: '',
            },
            srvcId: {
              name: this.$i18n.t('service.rule.rightPanel.label3'),
              value: '',
            },
            codeSystem: {
              name: '용어체계',
              value: '',
            },
            alarmRule: {
              name: this.$i18n.t('service.rule.rightPanel.label4'),
              value: [],
              isCustom: true,
            },
            genderCode: {
              name: this.$i18n.t('service.rule.rightPanel.label5'),
              value: '',
            },
            alarmTyCode: {
              name: this.$i18n.t('service.rule.rightPanel.label6'),
              value: '',
            },
            ruleTyCode: {
              name: this.$i18n.t('service.rule.rightPanel.label7'),
              value: '',
            },
            alarmCycleValue: {
              name: this.$i18n.t('service.rule.rightPanel.label8'),
              value: '',
            },
            alarmMsgCn: {
              name: this.$i18n.t('service.rule.rightPanel.label9'),
              value: '',
              isCustom: true,
            },
            crtrId: {
              name: this.$i18n.t('service.rule.rightPanel.label10'),
              value: '',
            },
            creatDt: {
              name: this.$i18n.t('service.rule.rightPanel.label11'),
              value: '',
              isCustom: true,
            },
            updusrId: {
              name: this.$i18n.t('service.rule.rightPanel.label12'),
              value: '',
            },
            updtDt: {
              name: this.$i18n.t('service.rule.rightPanel.label13'),
              value: '',
              isCustom: true,
            },
            subscriptions: {
              name: '서비스 구독 목록',
              value: [],
              isCustom: true,
            },
          },
        },
      ];
    },
    lang() {
      return this.$i18n.locale;
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
      this.getCommonCode();
    },
  },
};
</script>

<style scoped>
.bt-line24 {
  margin: 1.5px;
}

.alarm-rule-tab {
  overflow: auto;
}

.alarm-rule-tab table {
  border-top: 1px solid #ccc;
}

.alarm-rule-tab thead th {
  background: #f6f6f6 !important;
  text-align: left;
}

.sub__condition__tab {
  overflow: auto;
}

.sub__condition__tab table {
  border-top: 1px solid #ccc;
}

.sub__condition__tab thead th {
  background: #f6f6f6 !important;
  text-align: left;
}
</style>
