<template>
  <div class="pop" :style="isOpen ? 'display:block' : 'display:none'">
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width: 908px;">
      <div class="pop-head">
        <h2>
          {{
            type == 'insert'
              ? $t('service.rule.popUp.title-insert')
              : $t('service.rule.popUp.title-update')
          }}
        </h2>
        <button class="btn-x" @click="doClose()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="ruleValidate">
          <form>
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="130px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ ruleId }}<i class="star">*</i></th>
                  <td>
                    <template v-if="type === 'insert'">
                      <validate-form
                        :label="ruleId"
                        :rules="{
                          required: true,
                          min: 4,
                          max: 20,
                          regex: /^[0-9A-Za-z_-]{4,20}$/,
                        }"
                        :errorData="ruleIdInputErr"
                        inputClass="input-24"
                        inputStyle="width:618px"
                        v-model="alarmRuleInfo.alarmRuleId"
                        maxlength="20"
                        @input="initData()"
                        @keydown="inputLiteralCheck"
                      />
                      <button
                        type="button"
                        class="bt-line24"
                        @click="doCheckExistAlarmRuleId()"
                      >
                        {{ $t('common.btn.check-dupl') }}
                      </button>
                    </template>
                    <template v-else>
                      <validate-form
                        :label="ruleId"
                        :rules="{
                          required: true,
                          min: 4,
                          max: 20,
                          regex: /^[0-9A-Za-z_-]{4,20}$/,
                        }"
                        :errorData="ruleIdInputErr"
                        inputClass="input-24 w-100"
                        v-model="alarmRuleInfo.alarmRuleId"
                        maxlength="20"
                        :disabled="true"
                      />
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ ruleName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="ruleName"
                      :rules="{
                        required: true,
                        min: 4,
                        max: 100,
                      }"
                      inputClass="input-24 w-100"
                      v-model="alarmRuleInfo.alarmRuleNm"
                      maxlength="100"
                      @keydown="inputLiteralCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('service.rule.popUp.label3') }}<i class="star">*</i>
                  </th>
                  <td>
                    <select
                      class="input-24 w-100 mt-3"
                      v-model="alarmRuleInfo.srvcId"
                    >
                      <option value="">{{
                        $t('common.select', { target: '서비스' })
                      }}</option>
                      <option
                        v-for="srvc in srvcList"
                        :key="srvc.srvcId"
                        :value="srvc.srvcId"
                      >
                        {{ srvc.srvcNm }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>용어체계<i class="star">*</i></th>
                  <td>
                    <select
                      class="input-24 w-100 mt-3"
                      @change="changeCodeSystem()"
                      v-model="alarmRuleInfo.codeSystem"
                    >
                      <option value="">
                        {{ $t('common.select', { target: '용어체계' }) }}
                      </option>
                      <option value="kostom">
                        KOSTOM
                      </option>
                      <option value="snomed_ct">
                        SNOMED CT
                      </option>
                      <option value="loinc">
                        LOINC
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('service.rule.popUp.label4') }}<i class="star">*</i>
                  </th>
                  <td>
                    <table
                      class="table-style-default"
                      style="max-height:200px; overflow: auto;"
                    >
                      <colgroup>
                        <col width="10%" />
                        <col width="22%" />
                        <col width="22%" />
                        <col width="22%" />
                        <col width="18%" />
                        <col width="6%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th>{{ $t('service.rule.popUp.subHeader1') }}</th>
                          <th>세부항목</th>
                          <th>{{ $t('service.rule.popUp.subHeader2') }}</th>
                          <th>{{ $t('service.rule.popUp.subHeader3') }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in alarmRuleInfo.ruleList"
                          :key="index"
                        >
                          <td>
                            {{
                              `${$t('service.rule.popUp.label9')} ${index + 1}`
                            }}
                          </td>
                          <td>
                            <select
                              class="sort-input-select pop-select"
                              v-model="item.upperMesureCodeSn"
                              @change="changeUpperMesureCodeSn(index)"
                            >
                              <option value="">
                                {{ $t('common.select', { target: '항목' }) }}
                              </option>
                              <option
                                v-for="(fhirCode,
                                index) in fhirCodesByCodeSystem"
                                :key="index"
                                :value="fhirCode.mesureCodeSn"
                              >
                                {{ fhirCode.mesureCodeNm }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="sort-input-select pop-select"
                              v-model="item.mesureCodeSn"
                              :disabled="item.mesureCodes.length < 1"
                            >
                              <option value="">
                                {{
                                  $t('common.select', { target: '세부항목' })
                                }}
                              </option>
                              <option
                                v-for="(childFHIRCode,
                                index) in item.mesureCodes"
                                :key="index"
                                :value="childFHIRCode.mesureCodeSn"
                              >
                                {{ childFHIRCode.mesureCodeNm }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="sort-input-select pop-select"
                              v-model="item.scopeCode"
                            >
                              <option value="">
                                {{ $t('common.select', { target: '범위' }) }}
                              </option>
                              <option
                                v-for="(ruleCommonScopeCode,
                                index) in ruleCommonScopeCodes"
                                :key="index"
                                :value="ruleCommonScopeCode.codeId"
                              >
                                {{ ruleCommonScopeCode.codeName }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="number"
                              class="input-24 w-100 pop-input"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              maxlength="5"
                              v-model="item.ruleValue"
                            />
                          </td>
                          <td>
                            <input
                              type="button"
                              class="bt-line22"
                              :class="
                                alarmRuleInfo.ruleList.length === index + 1
                                  ? 'bt-num-plus'
                                  : 'bt-num-minus'
                              "
                              @click="manageRow($event, index)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('service.rule.popUp.label5') }}<i class="star">*</i>
                  </th>
                  <td>
                    <div class="radio-wrapper">
                      <input
                        type="radio"
                        id="common"
                        class="radiobox"
                        name="gender"
                        value="C"
                        v-model="alarmRuleInfo.genderCode"
                      />
                      <label for="common" class="label">
                        공통
                      </label>
                      <input
                        type="radio"
                        id="male"
                        class="radiobox"
                        name="gender"
                        value="M"
                        v-model="alarmRuleInfo.genderCode"
                      />
                      <label for="male" class="label">
                        {{ $t('service.rule.popUp.gender-label1') }}
                      </label>
                      <input
                        type="radio"
                        id="female"
                        class="radiobox"
                        name="gender"
                        value="F"
                        v-model="alarmRuleInfo.genderCode"
                      />
                      <label for="female" class="label">
                        {{ $t('service.rule.popUp.gender-label2') }}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('service.rule.popUp.label6') }}<i class="star">*</i>
                  </th>
                  <td>
                    <div class="radio-wrapper">
                      <input
                        type="radio"
                        id="andCondition"
                        class="radiobox"
                        name="alarmTyCode"
                        value="and"
                        v-model="alarmRuleInfo.alarmTyCode"
                      />
                      <label for="andCondition" class="label">
                        {{ $t('service.rule.popUp.alarmType-label1') }}
                      </label>
                      <input
                        type="radio"
                        id="orCondition"
                        class="radiobox"
                        name="alarmTyCode"
                        value="or"
                        v-model="alarmRuleInfo.alarmTyCode"
                      />
                      <label for="orCondition" class="label">
                        {{ $t('service.rule.popUp.alarmType-label2') }}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('service.rule.popUp.label7') }}<i class="star">*</i>
                  </th>
                  <td>
                    <validate-form
                      type="number"
                      :rules="{ max: 11, numeric: true }"
                      inputClass="input-24 w-100"
                      v-model="alarmRuleInfo.alarmCycleValue"
                      maxlength="11"
                      @keydown="inputNumberCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ message }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="message"
                      :rules="{ required: true, min: 4, max: 1000 }"
                      inputClass="input-24 w-100"
                      v-model="alarmRuleInfo.alarmMsgCn"
                      maxlength="1000"
                      @keydown="inputLiteralCheck"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </validation-observer>
        <p class="pop-btn-group">
          <input
            type="button"
            :value="btnSubmit"
            class="bt-line30 cr-orange"
            @click="doConfirm()"
          />
          <input
            type="button"
            :value="btnCancel"
            class="bt-line30 cr-default"
            @click="doClose()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { getExistAlarmRuleId } from '@/api/serviceApi';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    alarmRuleInfo: {
      type: Object,
      required: true,
    },
    srvcList: {
      type: Array,
      required: true,
    },
    fhirCodes: {
      type: Array,
      required: true,
    },
    fhirCodesByCodeSystem: {
      type: Array,
      required: true,
    },
    ruleCommonScopeCodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isPassExistAlarmRuleId: false,
    };
  },
  mixins: [CommonMixins],
  components: {
    ValidateForm,
  },
  computed: {
    ruleId() {
      return this.$i18n.t('service.rule.popUp.label1');
    },
    ruleName() {
      return this.$i18n.t('service.rule.popUp.label2');
    },
    message() {
      return this.$i18n.t('service.rule.popUp.label8');
    },
    ruleIdInputErr() {
      return {
        regex: this.$i18n.t('common.validate.id-format'),
      };
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
  },
  methods: {
    doReset() {
      this.$refs.ruleValidate.reset();
    },
    doClose() {
      this.initData();
      this.$emit('close');
    },
    validRuleList(ruleList) {
      let failIdx;

      for (let i in ruleList) {
        const rule = ruleList[i];
        const {
          upperMesureCodeSn,
          mesureCodeSn,
          mesureCodes,
          scopeCode,
          ruleValue,
        } = rule;

        if (!upperMesureCodeSn || !scopeCode || !ruleValue) {
          failIdx = i;
          break;
        }

        if (upperMesureCodeSn && mesureCodes.length > 0 && !mesureCodeSn) {
          failIdx = i;
          break;
        }
      }

      return failIdx;
    },
    async doConfirm() {
      const valid = await this.$refs.ruleValidate.validate();
      if (!valid) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      if (this.type === 'insert') {
        if (!this.isPassExistAlarmRuleId) {
          const field = this.ruleId;
          this.$dialogs.alert(
            this.$i18n.t('common.alert.invalid-dupl', { field }),
          );
          return;
        }
      }

      if (this.alarmRuleInfo.srvcId === '') {
        const target = this.getPostWord(
          this.$i18n.t('service.subscription.target-service'),
          '을',
          '를',
        );

        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );

        return false;
      }

      if (this.alarmRuleInfo.codeSystem === '') {
        const target = this.getPostWord('용어체계', '을', '를');

        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      let failIdx = this.validRuleList(this.alarmRuleInfo.ruleList);
      if (failIdx) {
        let conditionIdx = Number(failIdx) + 1;
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-condition', { conditionIdx }),
        );
        return false;
      }

      this.initData();
      this.$emit('confirm');
    },
    manageRow({ target }, index) {
      if (target.classList.contains('bt-num-plus')) {
        this.$emit('addRow', this.type);
      } else {
        this.$emit('deleteRow', index, this.type);
      }
    },
    async doCheckExistAlarmRuleId() {
      const alarmRuleId = this.alarmRuleInfo.alarmRuleId;

      if (!alarmRuleId) {
        const field = this.getPostWord(this.ruleId, '을', '를');
        this.$dialogs.alert(
          this.$i18n.t('common.alert.required-field', {
            field,
          }),
        );
        return;
      }

      try {
        const { data } = await getExistAlarmRuleId(alarmRuleId);
        const { valid, message } = data;
        valid
          ? (this.isPassExistAlarmRuleId = true)
          : (this.isPassExistAlarmRuleId = false);
        this.$dialogs.alert(message);
      } catch (error) {
        console.log('error', error);
      }
    },
    initData() {
      this.isPassExistAlarmRuleId = false;
    },
    changeCodeSystem() {
      this.$emit('changeCodeSystem', this.type);
    },
    changeUpperMesureCodeSn(index) {
      this.$emit('changeUpperMesureCodeSn', this.type, index);
    },
  },
};
</script>

<style scoped>
.radiobox {
  vertical-align: middle;
  width: 23px;
  height: 18px;
}

.label {
  vertical-align: middle;
}

.radiobox ~ input {
  margin-left: 20px;
}

.table-style-default > thead > tr > th {
  text-align: center;
}

.sort-input-select {
  width: 140px;
  text-align: center;
}
</style>
