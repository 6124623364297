import { service } from './index';

/**
 * 서비스 관리 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getServiceList(reqObj) {
  return service.post('/management/list', reqObj);
}

/**
 * 서비스 관리 상세 조회
 * @param {*} reqObj
 * @returns
 */
function getServiceDetail(reqObj) {
  return service.post('/management/detail', reqObj);
}

/**
 * 서비스 등록
 * @param {*} reqObj
 * @returns
 */
function insertService(reqObj) {
  return service.post('/management', reqObj);
}

/**
 * 서비스 수정
 * @param {*} reqObj
 * @returns
 */
function updateService(reqObj) {
  return service.post('/management/update', reqObj);
}

/**
 * 서비스 삭제
 * @param {*} reqObj
 * @returns
 */
function deleteService(reqObj) {
  return service.post('/management/delete', reqObj);
}

/**
 * 상태변경 처리
 * @param {*} reqObj
 * @returns
 */
function updateServiceApprove(reqObj) {
  return service.post('/management/approve/update', reqObj);
}

/**
 * 서비스 아이디 중복체크
 * @param {*} reqObj
 * @returns
 */
function checkSrvcIdDuplication(reqObj) {
  return service.post('/management/duplicate', reqObj);
}

/**
 * 시크릿 재발급
 * @param {*} reqObj
 * @returns
 */
function resetSecret(reqObj) {
  return service.post('/management/reset', reqObj);
}

/**
 * 승인 이메일 전송
 * @param {*} reqObj
 * @returns
 */
function sendApprovalEmail(reqObj) {
  return service.post('/management/send', reqObj);
}

/**
 * 인증타입 변경
 * @param {*} reqObj
 * @returns
 */
function updateGrantType(reqObj) {
  return service.post('/management/granttypes', reqObj);
}

/**
 * 회원 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getUserList(reqObj) {
  return service.post('/user/list', reqObj);
}

/**
 * 회원 목록 상세 조회
 * @param {*} userId
 * @returns
 */
function getUserDetail({ userId }) {
  return service.post(`/user/detail`, { userId });
}

/**
 * 회원가입이력 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getHistoryList(reqObj) {
  return service.post('/history/list', reqObj);
}

/**
 * 앱 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getAppList(reqObj) {
  return service.post('/app/list', reqObj);
}

/**
 * 앱 상세 조회
 * @param {*} appSn
 * @returns
 */
function getAppDetail(appSn) {
  return service.post(`/app/detail`, { appSn });
}

/**
 * 앱 등록
 * @param {*} reqObj
 * @returns
 */
function insertApp(reqObj) {
  return service.post('/app', reqObj);
}

/**
 * 앱 수정
 * @param {*} reqObj
 * @returns
 */
function updateApp(reqObj) {
  return service.post('/app/update', reqObj);
}

/**
 * 앱 삭제
 * @param {*} appSn
 * @returns
 */
function deleteApp(appSn) {
  return service.post(`/app/delete`, { appSn });
}

/**
 * 알람 조건 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getRuleList(reqObj) {
  return service.post('/rule/list', reqObj);
}

/**
 * 알람 조건 ID 중복 조회
 * @param {*} alarmRuleId
 * @returns
 */
function getExistAlarmRuleId(alarmRuleId) {
  return service.get(`/rule/${alarmRuleId}`);
}

/**
 * 알람 조건 등록
 * @param {*} reqObj
 * @returns
 */
function insertRule(reqObj) {
  return service.post('/rule', reqObj);
}

/**
 * 알람 조건 수정
 * @param {*} reqObj
 * @returns
 */
function updateRule(reqObj) {
  return service.put('/rule', reqObj);
}

/**
 * 알람 조건 삭제
 * @param {*} alarmRuleSn
 * @returns
 */
function deleteRule(alarmRuleSn) {
  return service.delete(`/rule/${alarmRuleSn}`);
}

/**
 * 구독 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getSubscriptions(reqObj) {
  return service.post(`/subscription/list`, reqObj);
}

/**
 * 구독 삭제
 * @param {*} srvcId
 * @returns
 */
function deleteSubscription(srvcId) {
  return service.delete(`/subscription/${srvcId}`);
}

/**
 * 구독 신청
 * @param {*} reqObj
 * @returns
 */
function insertSubscription(reqObj) {
  return service.post(`/subscription`, reqObj);
}

/**
 * 구독 수정
 * @param {*} reqObj
 * @returns
 */
function updateSubscription(reqObj) {
  return service.put(`/subscription`, reqObj);
}

/**
 * 서비스에 따라 구독 조회
 * @param {*} srvcId
 * @returns
 */
function getSubscriptionBySrvcId(srvcId) {
  return service.get(`/rule/subscription/${srvcId}`);
}

export {
  getServiceList,
  getServiceDetail,
  insertService,
  updateService,
  deleteService,
  updateServiceApprove,
  checkSrvcIdDuplication,
  resetSecret,
  sendApprovalEmail,
  updateGrantType,
  getUserList,
  getUserDetail,
  getHistoryList,
  getAppList,
  getAppDetail,
  insertApp,
  updateApp,
  deleteApp,
  getRuleList,
  getExistAlarmRuleId,
  insertRule,
  updateRule,
  deleteRule,
  getSubscriptions,
  deleteSubscription,
  insertSubscription,
  updateSubscription,
  getSubscriptionBySrvcId,
};
