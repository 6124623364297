<template>
  <div class="pop" :style="isOpen ? 'display:block' : 'display:none'">
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width: 1300px;">
      <div class="pop-head">
        <h2>
          {{
            popUpMode === 'insert'
              ? $t('service.subscription.popUp.title-insert')
              : $t('service.subscription.popUp.title-update')
          }}
        </h2>
        <button class="btn-x" @click="doClose()"></button>
      </div>
      <div class="pop-body" style="overflow:unset">
        <validation-observer ref="subscriptionValidate">
          <form>
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="130px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    {{ $t('service.subscription.popUp.label1')
                    }}<i class="star">*</i>
                  </th>
                  <td>
                    <select
                      class="input-24 w-100 mt-3"
                      v-model="subscriptionInfo.srvcId"
                      :disabled="popUpMode === 'update' ? true : false"
                    >
                      <option value="">{{
                        $t('common.select', { target: '서비스' })
                      }}</option>
                      <option
                        v-for="(srvc, index) in srvcs"
                        :key="index"
                        :value="srvc.srvcId"
                      >
                        {{ srvc.srvcNm }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>용어체계<i class="star">*</i></th>
                  <td>
                    <select
                      class="input-24 w-100 mt-3"
                      v-model="subscriptionInfo.codeSystem"
                      @change="changeCodeSystem"
                    >
                      <option value="">{{
                        $t('common.select', { target: '용어체계' })
                      }}</option>
                      <option value="kostom">KOSTOM</option>
                      <option value="snomed_ct">SNOMED CT</option>
                      <option value="loinc">LOINC</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('service.subscription.popUp.label2')
                    }}<i class="star">*</i>
                  </th>
                  <td>
                    <table
                      class="table-style-default"
                      style="max-height:200px; overflow: auto;"
                    >
                      <colgroup>
                        <col width="5%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="5%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            {{ $t('service.subscription.popUp.subHeader1') }}
                          </th>
                          <th>세부항목</th>
                          <th>유형</th>
                          <th>최소 값</th>
                          <th>최대 값</th>
                          <th>{{ expDt }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(phrServiceSubsc,
                          index) in subscriptionInfo.phrServiceSubscs"
                          :key="index"
                        >
                          <td>
                            {{ index + 1 }}
                          </td>
                          <td>
                            <select
                              class="sort-input-select pop-select"
                              v-model="phrServiceSubsc.upperMesureCodeSn"
                              @change="changeUpperMesureCodeSn(index)"
                            >
                              <option value="">{{
                                $t('common.select', { target: '항목' })
                              }}</option>
                              <option
                                v-for="(upperFHIRCode,
                                index) in fhirCodesByCodeSystem"
                                :key="index"
                                :value="upperFHIRCode.mesureCodeSn"
                              >
                                {{ upperFHIRCode.mesureCodeNm }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="sort-input-select pop-select"
                              v-model="phrServiceSubsc.mesureCodeSn"
                              :disabled="phrServiceSubsc.mesureCodes.length < 1"
                            >
                              <option value="">
                                {{
                                  $t('common.select', { target: '세부항목' })
                                }}
                              </option>
                              <option
                                v-for="(childFHIRCode,
                                index) in phrServiceSubsc.mesureCodes"
                                :key="index"
                                :value="childFHIRCode.mesureCodeSn"
                              >
                                {{ childFHIRCode.mesureCodeNm }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="sort-input-select pop-select"
                              v-model="phrServiceSubsc.scopeCode"
                            >
                              <option value="">{{
                                $t('common.select', { target: '유형' })
                              }}</option>
                              <option
                                v-for="(commonScopeCode,
                                index) in commonScopeCodes"
                                :key="index"
                                :value="commonScopeCode.codeId"
                              >
                                {{ commonScopeCode.codeName }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              class="input-24"
                              v-model="phrServiceSubsc.minValue"
                            />
                          </td>
                          <td>
                            <input
                              class="input-24"
                              v-model="phrServiceSubsc.maxValue"
                              :disabled="
                                phrServiceSubsc.scopeCode === '1' ||
                                  phrServiceSubsc.scopeCode === '2' ||
                                  phrServiceSubsc.scopeCode === '3'
                              "
                            />
                          </td>
                          <td>
                            <vc-date-picker
                              mode="dateTime"
                              :min-date="new Date()"
                              :model-config="modelConfig"
                              color="orange"
                              popover-visibility="focus"
                              v-model="phrServiceSubsc.expDt"
                            >
                              <template v-slot="{ togglePopover }">
                                <validate-form
                                  :label="expDt"
                                  name="expDt"
                                  v-model="phrServiceSubsc.expDt"
                                  :rules="{
                                    min_value: new Date(),
                                  }"
                                  inputClass="input-24 w-100"
                                  placeholder="yyyy/mm/dd HH:MM:00"
                                  maxlength="200"
                                  @click="togglePopover()"
                                />
                              </template>
                            </vc-date-picker>
                          </td>
                          <td>
                            <input
                              type="button"
                              class="bt-line22"
                              :class="
                                subscriptionInfo.phrServiceSubscs.length ===
                                index + 1
                                  ? 'bt-num-plus'
                                  : 'bt-num-minus'
                              "
                              @click="manageRow($event, index)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>{{ pushUri }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="pushUri"
                      :name="'pushRedirectUrl'"
                      :input-class="'input-24 w-100'"
                      v-model="subscriptionInfo.pushRedirectUrl"
                      :rules="{
                        required: true,
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      :placeholder="urlPlaceholder"
                      maxlength="255"
                      dataType="URL"
                    ></validate-form>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </validation-observer>
        <p class="pop-btn-group">
          <input
            type="button"
            :value="btnSubmit"
            class="bt-line30 cr-orange"
            @click="doConfirm()"
          />
          <input
            type="button"
            :value="btnCancel"
            class="bt-line30 cr-default"
            @click="doClose()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    popUpMode: {
      type: String,
      required: true,
    },
    subscriptionInfo: {
      type: Object,
      required: true,
    },
    srvcs: {
      type: Array,
      required: true,
    },
    fhirCodesByCodeSystem: {
      type: Array,
      required: true,
    },
    commonScopeCodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY/MM/DD HH:mm:00', // Uses 'iso' if missing
      },
    };
  },
  components: { ValidateForm },
  mixins: [CommonMixins],
  computed: {
    expDt() {
      return this.$i18n.t('service.subscription.popUp.subHeader2');
    },
    pushUri() {
      return this.$i18n.t('service.subscription.popUp.label3');
    },
    urlPlaceholder() {
      return this.$i18n.t('common.placeholder.url-format');
    },
    urlInputErr() {
      return {
        regex: this.$i18n.t('common.validate.url-format'),
      };
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
  },
  methods: {
    doReset() {
      this.$refs.subscriptionValidate.reset();
    },
    doClose() {
      this.$emit('close', this.popUpMode);
    },
    manageRow({ target }, index) {
      if (target.classList.contains('bt-num-plus')) {
        this.$emit('addRow', this.popUpMode);
      } else {
        this.$emit('deleteRow', index, this.popUpMode);
      }
    },
    validPhrServiceSubsc(phrServiceSubscs) {
      let failIdx;
      for (let i in phrServiceSubscs) {
        let phrServiceSubsc = phrServiceSubscs[i];
        const {
          upperMesureCodeSn,
          scopeCode,
          expDt,
          minValue,
          maxValue,
        } = phrServiceSubsc;

        if (!upperMesureCodeSn || !expDt) {
          failIdx = i;
          break;
        }

        if (!scopeCode && (minValue || maxValue)) {
          failIdx = i;
          break;
        } else if (scopeCode === '1' && !minValue) {
          failIdx = i;
          break;
        } else if (scopeCode === '2' && !minValue) {
          failIdx = i;
          break;
        } else if (scopeCode === '3' && !minValue) {
          failIdx = i;
          break;
        } else if (scopeCode === '4' && !minValue && !maxValue) {
          failIdx = i;
          break;
        } else if (scopeCode === '5' && !minValue && !maxValue) {
          failIdx = i;
          break;
        }
      }
      return failIdx;
    },
    async doConfirm() {
      const result = await this.$refs.subscriptionValidate.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return false;
      }

      if (this.subscriptionInfo.srvcId === '') {
        const target = this.getPostWord(
          this.$i18n.t('service.subscription.target-service'),
          '을',
          '를',
        );

        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );

        return false;
      }

      if (this.subscriptionInfo.codeSystem === '') {
        const target = this.getPostWord('용어체계', '을', '를');

        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );

        return false;
      }

      let failIdx = this.validPhrServiceSubsc(
        this.subscriptionInfo.phrServiceSubscs,
      );

      if (failIdx) {
        let conditionIdx = Number(failIdx) + 1;
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-condition', { conditionIdx }),
        );
        return false;
      }

      if (this.subscriptionInfo.pushRedirectUrl === '') {
        this.$dialogs.alert('Push Redirect URI를 입력해주세요.');
        return false;
      }

      this.$emit('confirm', this.popUpMode);
    },
    changeCodeSystem() {
      this.$emit('changeCodeSystem', this.popUpMode);
    },
    changeUpperMesureCodeSn(index) {
      this.$emit('changeUpperMesureCodeSn', this.popUpMode, index);
    },
  },
};
</script>

<style scoped>
.pop-body {
  max-height: 100%;
}

.table-style-default {
  text-align: center;
}

.table-style-default > thead > tr > th {
  text-align: center;
}

.sort-input-select {
  width: 140px;
  text-align: center;
}

.bt-line22 {
  min-width: 0px;
}
</style>
