<template>
  <!-- 등록 팝업 -->
  <div class="pop pop-drag" :style="isOpen ? 'display:block' : 'display:none'">
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width:500px">
      <div class="pop-head">
        <h2>{{ $t('service.management.insertServicePopUp.title') }}</h2>
        <button class="btn-x" @click="doClose()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="insertServiceValidate">
          <form>
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="140px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ serviceId }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="serviceId"
                      :rules="{
                        required: true,
                        min: 4,
                        max: 20,
                        regex: /^[0-9A-Za-z_-]{4,20}$/,
                      }"
                      :errorData="serviceIdInputErr"
                      inputClass="input-24"
                      inputStyle="width:200px"
                      v-model="insertSrvc.srvcId"
                      maxlength="20"
                      @input="isSrvcIdDuplication = true"
                      @keydown="inputLiteralCheck"
                    />
                    <button
                      type="button"
                      class="bt-line24"
                      @click="doCheckSrvcIdDuplication()"
                    >
                      {{ $t('common.btn.check-dupl') }}
                    </button>
                  </td>
                </tr>
                <tr>
                  <th>{{ serviceName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="serviceName"
                      :rules="{ required: true, min: 4, max: 30 }"
                      inputClass="input-24 w-100"
                      v-model="insertSrvc.srvcNm"
                      maxlength="30"
                      @keydown="inputLiteralCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ redirectUri }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="redirectUri"
                      :rules="{
                        required: true,
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="insertSrvc.webServerRedirectUri"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ userRegistAlarmUri }}</th>
                  <td>
                    <validate-form
                      :label="userRegistAlarmUri"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="insertSrvc.mberRegistNtcnUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ serviceApprovalAlarmUri }}</th>
                  <td>
                    <validate-form
                      :label="serviceApprovalAlarmUri"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="insertSrvc.srvcConfmNtcnUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ pushUrl }}</th>
                  <td>
                    <validate-form
                      :label="pushUrl"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="insertSrvc.pushRedirectUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ feedUrl }}</th>
                  <td>
                    <validate-form
                      :label="feedUrl"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="insertSrvc.feedRedirectUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ companyName }}</th>
                  <td>
                    <validate-form
                      :label="companyName"
                      :rules="{ max: 25 }"
                      inputClass="input-24 w-100"
                      v-model="insertSrvc.cmpnyNm"
                      maxlength="25"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ adminTel }}</th>
                  <td>
                    <validate-form
                      type="number"
                      :label="adminTel"
                      :rules="{ max: 11, regex: /^\d{0,30}$/ }"
                      :errorData="telInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="telPlaceholder"
                      v-model="insertSrvc.chargerTelno"
                      maxlength="11"
                      @keydown="inputNumberCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ adminEmail }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="adminEmail"
                      :rules="{ required: true, max: 50, email: true }"
                      inputClass="input-24 w-100"
                      v-model="insertSrvc.chargerEmail"
                      maxlength="50"
                      dataType="EMAIL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ approvalStatus }}<i class="star">*</i></th>
                  <td>
                    <select
                      class="input-24"
                      name="srvcSttus"
                      style="width:100px"
                      v-model="insertSrvc.srvcSttus"
                    >
                      <option
                        v-for="srvcSttusCode in srvcSttusCodes"
                        :key="srvcSttusCode.codeId"
                        :value="srvcSttusCode.codeId"
                      >
                        {{ srvcSttusCode.codeName }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>{{ description }}</th>
                  <td>
                    <validate-form
                      type="textarea"
                      :label="description"
                      rows="5"
                      :rules="{ max: 300 }"
                      inputClass="input-24 w-100"
                      v-model="insertSrvc.dc"
                      maxlength="300"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnRegister"
              class="bt-line30 cr-orange"
              @click="doInsertService()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="doClose()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { checkSrvcIdDuplication, insertService } from '@/api/serviceApi';
import ValidateForm from '@/components/ValidateForm.vue';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    srvcSttusCodes: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [CommonMixins],
  data() {
    return {
      insertSrvc: {
        srvcId: '',
        srvcNm: '',
        webServerRedirectUri: '',
        mberRegistNtcnUrl: '',
        srvcConfmNtcnUrl: '',
        pushRedirectUrl: '',
        feedRedirectUrl: '',
        cmpnyNm: '',
        chargerTelno: '',
        chargerEmail: '',
        srvcSttus: '1',
        dc: '',
      },
      isSrvcIdDuplication: true,
    };
  },
  components: {
    ValidateForm,
  },
  computed: {
    serviceId() {
      return this.$i18n.t('service.management.insertServicePopUp.label1');
    },
    serviceName() {
      return this.$i18n.t('service.management.insertServicePopUp.label2');
    },
    redirectUri() {
      return this.$i18n.t('service.management.insertServicePopUp.label3');
    },
    userRegistAlarmUri() {
      return this.$i18n.t('service.management.insertServicePopUp.label4');
    },
    serviceApprovalAlarmUri() {
      return this.$i18n.t('service.management.insertServicePopUp.label5');
    },
    pushUrl() {
      return this.$i18n.t('service.management.insertServicePopUp.label6');
    },
    feedUrl() {
      return this.$i18n.t('service.management.insertServicePopUp.label7');
    },
    companyName() {
      return this.$i18n.t('service.management.insertServicePopUp.label8');
    },
    adminTel() {
      return this.$i18n.t('service.management.insertServicePopUp.label9');
    },
    adminEmail() {
      return this.$i18n.t('service.management.insertServicePopUp.label10');
    },
    approvalStatus() {
      return this.$i18n.t('service.management.insertServicePopUp.label11');
    },
    description() {
      return this.$i18n.t('service.management.insertServicePopUp.label12');
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    urlPlaceholder() {
      return this.$i18n.t('common.placeholder.url-format');
    },
    telPlaceholder() {
      return this.$i18n.t('common.placeholder.tel-format');
    },
    serviceIdInputErr() {
      return {
        regex: this.$i18n.t('common.validate.id-format'),
      };
    },
    urlInputErr() {
      return {
        regex: this.$i18n.t('common.validate.url-format'),
      };
    },
    telInputErr() {
      return {
        regex: this.$i18n.t('common.validate.tel-format'),
      };
    },
  },
  methods: {
    doClose() {
      this.$refs.insertServiceValidate.reset();
      this.resetInsertSrvc();
      this.$emit('close', false);
    },
    resetInsertSrvc() {
      this.insertSrvc.srvcId = '';
      this.insertSrvc.srvcNm = '';
      this.insertSrvc.webServerRedirectUri = '';
      this.insertSrvc.mberRegistNtcnUrl = '';
      this.insertSrvc.srvcConfmNtcnUrl = '';
      this.insertSrvc.pushRedirectUrl = '';
      this.insertSrvc.feedRedirectUrl = '';
      this.insertSrvc.cmpnyNm = '';
      this.insertSrvc.chargerTelno = '';
      this.insertSrvc.chargerEmail = '';
      this.insertSrvc.srvcSttus = '1';
      this.insertSrvc.dc = '';
      this.isSrvcIdDuplication = true;
    },
    async doCheckSrvcIdDuplication() {
      try {
        if (!this.insertSrvc.srvcId) {
          const field = this.getPostWord(this.serviceId, '을', '를');
          this.$dialogs.alert(
            this.$i18n.t('common.alert.required-field', {
              field,
            }),
          );
          return;
        }

        const reqObj = {
          srvcId: this.insertSrvc.srvcId.trim(),
        };
        const response = await checkSrvcIdDuplication(reqObj);
        const { valid } = response.data;
        if (valid) {
          this.$dialogs.alert(
            this.$i18n.t('common.alert.valid-target', {
              target: this.serviceId,
            }),
          );
          this.isSrvcIdDuplication = false;
        } else {
          this.$dialogs.alert(
            this.$i18n.t('common.alert.invalid-target-inUse', {
              target: this.serviceId,
            }),
          );
          this.isSrvcIdDuplication = true;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async doInsertService() {
      const result = await this.$refs.insertServiceValidate.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      if (this.isSrvcIdDuplication) {
        const field = this.serviceId;
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-dupl', { field }),
        );
        return;
      }

      try {
        Object.keys(this.insertSrvc).map(
          k => (this.insertSrvc[k] = this.insertSrvc[k].trim()),
        );

        const reqObj = this.insertSrvc;

        const response = await insertService(reqObj);
        const { valid } = response.data;
        if (!valid) {
          this.$dialogs.alert(this.$i18n.t('common.alert.insert-fail'));
          return;
        }
        this.$dialogs
          .alert(this.$i18n.t('common.alert.insert-success'))
          .then(() => {
            this.doClose();
            this.$emit('serviceInserted');
          });
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style scoped></style>
