<template>
  <!-- 서비스 승인 팝업 -->
  <div class="pop" :style="isOpen ? 'display:block' : 'display:none'">
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width:350px;">
      <div class="pop-head">
        <h2>{{ $t('service.management.updateSttusPopUp.title') }}</h2>
        <button class="btn-x" @click="doClose()"></button>
      </div>
      <div class="pop-body">
        <div class="pop-question">
          <select
            class="input-24 w-100"
            name="ua_srvcSttus"
            v-model="updateSttus.srvcSttus"
          >
            <option
              v-for="srvcSttusCode in srvcSttusCodes"
              :key="srvcSttusCode.codeId"
              :value="srvcSttusCode.codeId"
            >
              {{ srvcSttusCode.codeName }}
            </option>
          </select>
        </div>
        <p class="pop-btn-group">
          <input
            type="button"
            :value="btnUpdateStatus"
            class="bt-line30 cr-orange"
            @click="doUpdateServiceSttus()"
          />
          <input
            type="button"
            :value="btnCancel"
            class="bt-line30"
            @click="doClose()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import { updateServiceApprove } from '@/api/serviceApi';

export default {
  props: {
    srvcSttusCodes: {
      type: Array,
      required: true,
    },
    serviceDetail: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      updateSttus: {
        srvcId: '',
        srvcSttus: '',
        oldSrvcSttus: '',
      },
    };
  },
  mixins: [CommonMixins],
  computed: {
    btnUpdateStatus() {
      return this.$i18n.t('common.btn.update-status');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
  },
  methods: {
    doClose() {
      this.setUpdateSttus();
      this.$emit('close', false);
    },
    setUpdateSttus() {
      this.updateSttus.srvcId = this.serviceDetail.srvcId;
      this.updateSttus.srvcSttus = this.serviceDetail.srvcSttus;
      this.updateSttus.oldSrvcSttus = this.serviceDetail.srvcSttus;
    },
    resetUpdateSttus() {
      Object.keys(this.updateSttus).map(k => (this.updateSttus[k] = ''));
    },
    async doUpdateServiceSttus() {
      if (this.updateSttus.srvcSttus === this.updateSttus.oldSrvcSttus) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.update-fail-sameBefore'),
        );
        return;
      }
      try {
        const reqObj = this.updateSttus;
        const response = await updateServiceApprove(reqObj);
        const { valid } = response.data;
        if (!valid) {
          this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
          return;
        }

        this.$dialogs
          .alert(this.$i18n.t('common.alert.update-success'))
          .then(() => {
            this.doClose();
            this.$emit('serviceSttusUpdated');
          });
      } catch (error) {
        console.log('error', error);
      }
    },
  },
  watch: {
    serviceDetail() {
      if (!this.isEmptyObject(this.serviceDetail)) {
        this.setUpdateSttus();
      } else {
        this.resetUpdateSttus();
      }
    },
  },
};
</script>

<style></style>
