<template>
  <!-- 인증타입 변경 팝업 -->
  <div class="pop" :style="isOpen ? 'display:block' : 'display:none'">
    <div class="popup-mask"></div>
    <div
      class="pop-content pop-ani pop-drag"
      style="width:500px; height:175px;"
    >
      <div class="pop-head">
        <h2>{{ $t('service.management.updateGrantPopUp.title') }}</h2>
        <button class="btn-x" @click="doClose()"></button>
      </div>
      <div class="pop-body">
        <div>
          <p style="margin: 10px;">
            {{ $t('service.management.updateGrantPopUp.label1') }} :
            <span class="c-orange">{{ serviceDetail.srvcId }}</span>
          </p>
          <div style="border-left: 1px solid #ccc">
            <v-data-table
              dense
              :headers="headers"
              :items="tableItems"
              hide-default-footer
            >
              <template v-slot:[`item.isUsed`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isUsed"
                  :ripple="false"
                  color="blue"
                ></v-simple-checkbox>
              </template>
            </v-data-table>
          </div>
          <p style="margin: 10px;" class="c-orange">
            {{ $t('service.management.updateGrantPopUp.desc') }}
          </p>
        </div>
        <p class="pop-btn-group">
          <input
            type="button"
            :value="btnSubmit"
            class="bt-line30 cr-orange"
            @click="doUpdateGrantType()"
          />
          <input
            type="button"
            :value="btnCancel"
            class="bt-line30"
            @click="doClose()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { updateGrantType } from '@/api/serviceApi';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    grantTypeCodes: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    serviceDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableItems: [],
    };
  },
  mixins: [CommonMixins],
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t(
            'service.management.updateGrantPopUp.table-column1',
          ),
          width: '60%',
          value: 'codeName',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$i18n.t(
            'service.management.updateGrantPopUp.table-column2',
          ),
          width: '40%',
          value: 'isUsed',
          align: 'center',
          sortable: false,
        },
      ];
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
  },
  methods: {
    doClose() {
      this.setTableItems();
      this.$emit('close', false);
    },
    setTableItems() {
      let tableItems = [];
      const { grantTypes } = this.serviceDetail;
      this.grantTypeCodes.forEach(grantTypeCode => {
        const { codeName } = grantTypeCode;
        if (grantTypes === null) {
          tableItems.push({ codeName: codeName, isUsed: false });
        } else {
          const findGrantType = grantTypes.find(
            grantType => grantType === codeName,
          );
          if (findGrantType) {
            tableItems.push({ codeName: findGrantType, isUsed: true });
          } else {
            tableItems.push({ codeName: codeName, isUsed: false });
          }
        }
      });
      this.tableItems = tableItems;
    },
    async doUpdateGrantType() {
      const passwordCheckResult = await this.$dialogs.password();
      if (passwordCheckResult.ok) {
        try {
          const checkedGrantTypes = this.tableItems
            .filter(tableItem => tableItem.isUsed === true)
            .map(tableItem => tableItem.codeName);

          const reqObj = {
            srvcId: this.serviceDetail.srvcId,
            grantTypes: checkedGrantTypes,
          };

          const response = await updateGrantType(reqObj);
          const { valid } = response.data;
          if (!valid) {
            this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
            return;
          }

          this.$dialogs
            .alert(this.$i18n.t('common.alert.update-success'))
            .then(() => {
              this.doClose();
              this.$emit('serviceGrantUpdated');
            });
        } catch (error) {
          console.log('error', error);
        }
      }
    },
  },
  watch: {
    serviceDetail() {
      if (!this.isEmptyObject(this.serviceDetail)) {
        this.setTableItems();
      } else {
        this.tableItems = [];
      }
    },
  },
};
</script>

<style></style>
