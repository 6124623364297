<template>
  <div>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :tableHeight="'603'"
          :headers="headers"
          :nowPerPage="nowPerPage"
          :items="serviceList"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :customSlotInfo="customSlotInfo"
          :searchOpts="searchSrvc"
          :loading="isTableOverlay"
          :reloadToggle="reloadToggle"
          @rowClick="rowClick"
          @getDataFromApi="doGetServiceList"
        >
          <template #searchOptions>
            <label for="search_srvcId" class="sort-label">
              · {{ $t('service.management.search-condition1') }}
            </label>
            <input
              type="text"
              id="search_srvcId"
              class="sort-input-text"
              v-model="searchSrvc.srvcId"
              maxlength="20"
              @keyup.enter="doSearch"
            />
            <label for="search_srvcNm" class="sort-label"
              >· {{ $t('service.management.search-condition2') }}</label
            >
            <input
              type="text"
              id="search_srvcNm"
              class="sort-input-text"
              v-model="searchSrvc.srvcNm"
              maxlength="30"
              @keyup.enter="doSearch"
            />
            <label for="search_srvcSttus" class="sort-label"
              >· {{ $t('service.management.search-condition3') }}
            </label>
            <select
              id="search_srvcSttus"
              class="sort-input-select"
              v-model="searchSrvc.srvcSttus"
              style="width:100px"
            >
              <option value="">
                {{ $t('common.all') }}
              </option>
              <option
                v-for="srvcAllSttusCode in srvcSttusCodes"
                :key="srvcAllSttusCode.codeId"
                :value="srvcAllSttusCode.codeId"
              >
                {{ srvcAllSttusCode.codeName }}
              </option>
            </select>
          </template>
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="isOpenInsertPopUp = true"
            />
          </template>
          <template v-slot:srvcSttus_custom="{ item }">
            <td
              class="text-center"
              :style="{
                color:
                  item.srvcSttus !== '-' ? getSttusColor(item.srvcSttus) : '',
              }"
            >
              {{ item.srvcSttus !== '-' ? getSttusText(item.srvcSttus) : '-' }}
            </td>
          </template>
          <!-- 길이 초과될 거 같은 컬럼의 경우, mouseOver 시 텍스트 표시 
              1. td-ellipsis 클래스 추가
              2. title 추가..
          -->
          <template v-slot:srvcNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.srvcNm">
              {{ item.srvcNm }}
            </td>
          </template>
          <template v-slot:createDate_custom="{ item }">
            <td class="text-center">
              {{
                item.createDate !== '-' ? getFormatDate(item.createDate) : '-'
              }}
            </td>
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :tableHeight="'710'"
          :tabOverlay="isTabOverlay"
          :tabItems="tabItems"
        >
          <template v-slot:chargerTelno_tab_custom="{ item }">
            <td>{{ item.value ? getPhoneNoText(item.value) : '-' }}</td>
          </template>

          <template v-slot:grantTypes_tab_custom="{ item }">
            <td>
              <div style="height: 75px; overflow-y:auto;">
                <span
                  v-html="item.value ? getGrantTypeText(item.value) : '-'"
                ></span>
              </div>
            </td>
          </template>

          <template v-slot:createDate_tab_custom="{ item }">
            <td>{{ item.value ? getFormatDate(item.value) : '-' }}</td>
          </template>

          <template #serviceInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdateStatus"
                  class="bt-line24 cr-gray"
                  @click="doOpenUpdateSttusPopUp()"
                />
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="doOpenUpdatePopUp()"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="doDeleteService()"
                />
              </p>
              <p class="f-right" style="margin-top: 5px;">
                <input
                  type="button"
                  :value="btnUpdateSecret"
                  class="bt-line24 cr-orange"
                  @click="doResetSecret()"
                />
                <input
                  type="button"
                  :value="btnUpdateAuthType"
                  class="bt-line24 cr-orange"
                  @click="doOpenUpdateGrantPopUp()"
                />
                <input
                  type="button"
                  :value="btnSendEmail"
                  class="bt-line24 cr-orange"
                  @click="doSendApprovalEmail()"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <insert-service-pop-up
      :srvcSttusCodes="srvcSttusCodes"
      :isOpen="isOpenInsertPopUp"
      @close="isValue => (isOpenInsertPopUp = isValue)"
      @serviceInserted="reloadToggle = !reloadToggle"
    ></insert-service-pop-up>

    <update-service-pop-up
      :srvcSttusCodes="srvcSttusCodes"
      :serviceDetail="clickedRowDetail"
      :isOpen="isOpenUpdatePopUp"
      @close="isValue => (isOpenUpdatePopUp = isValue)"
      @serviceUpdated="reloadToggle = !reloadToggle"
    ></update-service-pop-up>

    <update-sttus-pop-up
      :srvcSttusCodes="srvcSttusCodes"
      :serviceDetail="clickedRowDetail"
      :isOpen="isOpenUpdateSttusPopUp"
      @close="isValue => (isOpenUpdateSttusPopUp = isValue)"
      @serviceSttusUpdated="reloadToggle = !reloadToggle"
    ></update-sttus-pop-up>

    <update-grant-pop-up
      :grantTypeCodes="grantTypeCodes"
      :isOpen="isOpenUpdateGrantPopUp"
      :serviceDetail="clickedRowDetail"
      @close="isValue => (isOpenUpdateGrantPopUp = isValue)"
      @serviceGrantUpdated="reloadToggle = !reloadToggle"
    ></update-grant-pop-up>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import {
  getServiceList,
  getServiceDetail,
  deleteService,
  resetSecret,
  sendApprovalEmail,
} from '@/api/serviceApi';
import InsertServicePopUp from './InsertServicePopUp.vue';
import UpdateServicePopUp from './UpdateServicePopUp.vue';
import UpdateSttusPopUp from './UpdateSttusPopUp.vue';
import UpdateGrantPopUp from './UpdateGrantPopUp.vue';
import { getCommonCode } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';

// 승인완료
const SRVC_STTUS_APPROVED_CODE = '2';
// 승인거절
const SRVC_STTUS_REFUSE_CODE = '3';

export default {
  data() {
    return {
      customSlotInfo: [
        {
          name: 'srvcSttus',
          slotName: 'item.srvcSttus',
        },
        {
          name: 'srvcNm',
          slotName: 'item.srvcNm',
        },
        {
          name: 'createDate',
          slotName: 'item.createDate',
        },
      ],
      nowPerPage: 30,
      totalRecords: 0,
      totalPages: 0,
      serviceList: [],
      searchSrvc: {
        srvcId: '',
        srvcNm: '',
        srvcSttus: '',
      },
      isTableOverlay: false,
      isTabOverlay: false,
      reloadToggle: true,
      clickedRow: {},
      clickedRowDetail: {},
      isOpenInsertPopUp: false,
      isOpenUpdatePopUp: false,
      isOpenUpdateSttusPopUp: false,
      isOpenUpdateGrantPopUp: false,
      srvcSttusCodes: [],
      grantTypeCodes: [],
    };
  },
  mixins: [CommonMixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    InsertServicePopUp,
    UpdateServicePopUp,
    UpdateSttusPopUp,
    UpdateGrantPopUp,
  },
  created() {
    this.getGrantTypeCodes();
    this.getSrvcSttusCodes();
  },
  methods: {
    async getSrvcSttusCodes() {
      const { data } = await getCommonCode(COMMON_GROUP_CODE.CODE_SRVC_STTUS);
      this.srvcSttusCodes = data;
    },
    async getGrantTypeCodes() {
      const { data } = await getCommonCode(COMMON_GROUP_CODE.CODE_GRANT_TYPE);
      this.grantTypeCodes = data;
    },
    async doGetServiceList(searchParams) {
      try {
        this.isTableOverlay = true;
        this.doResetClicked();
        const {
          rows,
          page,
          sord,
          sidx,
          srvcNm,
          srvcId,
          srvcSttus,
        } = searchParams;

        const reqObj = {
          page,
          rows,
          sidx,
          sord,
          srvcNm: srvcNm.trim(),
          srvcId: srvcId.trim(),
          srvcSttus,
        };
        const response = await getServiceList(reqObj);
        const { rows: items, totalRecords, totalPages } = response.data;
        this.serviceList = items;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.isTableOverlay = false;
      }
    },
    getSttusText(srvcSttus) {
      let sttusText;
      this.srvcSttusCodes.forEach(srvcSttusCode => {
        const { codeId, codeName } = srvcSttusCode;
        if (srvcSttus === codeId) {
          sttusText = codeName;
        }
      });
      return sttusText;
    },
    getSttusColor(srvcSttus) {
      if (srvcSttus === SRVC_STTUS_APPROVED_CODE) {
        return '#38A1D1';
      } else if (srvcSttus === SRVC_STTUS_REFUSE_CODE) {
        return '#F25555';
      }
    },
    doResetClicked() {
      this.doResetTab();
      this.clickedRow = {};
      this.clickedRowDetail = {};
    },
    doSearch() {
      this.$refs.tableListComponent.search();
    },
    async doGetServiceDetail() {
      try {
        this.isTabOverlay = true;

        const reqObj = {
          srvcId: this.clickedRow.srvcId,
        };
        const response = await getServiceDetail(reqObj);
        const { detail, userList, envList } = response.data;
        const serviceInfoTab = this.tabItems[0];
        const userListTab = this.tabItems[1];
        const envListTab = this.tabItems[2];

        let keys = Object.keys(serviceInfoTab.selectedItem);
        keys.forEach(key => {
          serviceInfoTab.selectedItem[key].value = detail[key];
        });

        if (userList.length > 0) {
          userListTab.selectedItem = [];
          userList.forEach(user => {
            const { userId, userNm } = user;
            userListTab.selectedItem.push({ name: userId, value: userNm });
          });
        } else {
          userListTab.selectedItem = [];
        }

        if (envList.length > 0) {
          envListTab.selectedItem = [];
          envList.forEach(env => {
            const { analsEnvrnId, createDate } = env;
            envListTab.selectedItem.push({
              name: analsEnvrnId,
              value: this.getFormatDate(createDate),
            });
          });
        } else {
          envListTab.selectedItem = [];
        }

        this.clickedRowDetail = detail;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.isTabOverlay = false;
      }
    },
    rowClick(item) {
      this.clickedRow = item;
      this.doGetServiceDetail();
    },
    getGrantTypeText(grantTypes) {
      let grantTypeText = '';

      if (!grantTypes || grantTypes.length < 1) {
        return grantTypeText;
      }

      grantTypes.forEach(grantType => {
        grantTypeText += `${grantType} <br/>`;
      });

      return grantTypeText;
    },
    doResetTab() {
      const serviceInfoTab = this.tabItems[0];
      const userListTab = this.tabItems[1];
      const envListTab = this.tabItems[2];

      let keys = Object.keys(serviceInfoTab.selectedItem);
      keys.forEach(key => {
        serviceInfoTab.selectedItem[key].value = '';
      });
      userListTab.selectedItem = [];
      envListTab.selectedItem = [];
    },
    doOpenUpdateSttusPopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.management.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      this.isOpenUpdateSttusPopUp = true;
    },
    async doDeleteService() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.management.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }

      const { srvcId, srvcNm } = this.clickedRow;
      const result = await this.$dialogs.confirm(
        `<p>${this.$i18n.t(
          'service.management.target-serviceName',
        )} : <span class="c-orange">${srvcNm}</span></p>${this.$i18n.t(
          'common.alert.delete-confirm',
        )}`,
      );

      if (result.ok) {
        try {
          const reqObj = {
            srvcId,
          };
          const response = await deleteService(reqObj);
          const { valid } = response.data;
          if (!valid) {
            this.$dialogs.alert(this.$i18n.t('common.alert.delete-fail'));
            return;
          }
          this.$dialogs
            .alert(this.$i18n.t('common.alert.delete-success'))
            .then(() => {
              this.doSearch();
            });
        } catch (error) {
          console.log('error', error);
        }
      }
    },
    doOpenUpdatePopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.management.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-update', { target }),
        );
        return;
      }
      this.isOpenUpdatePopUp = true;
    },
    async doResetSecret() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.management.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      const { srvcId, srvcSttus } = this.clickedRow;

      if (srvcSttus !== SRVC_STTUS_APPROVED_CODE) {
        this.$dialogs.alert(this.$i18n.t('common.alert.service-deny'));
        return;
      }

      const result = await this.$dialogs.confirm(
        this.$i18n.t('common.alert.update-secret'),
      );

      if (result.ok) {
        const passwordCheckResult = await this.$dialogs.password();
        if (passwordCheckResult.ok) {
          try {
            const reqObj = {
              srvcId,
            };
            const response = await resetSecret(reqObj);
            const { valid } = response.data;
            if (!valid) {
              this.$dialogs.alert(
                this.$i18n.t('common.alert.update-secret-fail'),
              );
              return;
            }
            this.$dialogs
              .alert(this.$i18n.t('common.alert.update-secret-success'))
              .then(() => {
                this.doSearch();
              });
          } catch (error) {
            console.log('error', error);
          }
        }
      }
    },
    doOpenUpdateGrantPopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.management.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      const { srvcSttus } = this.clickedRow;
      if (srvcSttus !== SRVC_STTUS_APPROVED_CODE) {
        this.$dialogs.alert(this.$i18n.t('common.alert.service-deny'));
        return;
      }

      this.isOpenUpdateGrantPopUp = true;
    },
    async doSendApprovalEmail() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.management.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      const { srvcId, srvcSttus } = this.clickedRow;

      if (srvcSttus !== SRVC_STTUS_APPROVED_CODE) {
        this.$dialogs.alert(this.$i18n.t('common.alert.service-deny'));
        return;
      }

      try {
        const reqObj = {
          srvcId,
        };

        const response = await sendApprovalEmail(reqObj);
        const { valid } = response.data;
        if (!valid) {
          this.$dialogs.alert(this.$i18n.t('common.alert.send-fail'));
          return;
        }
        this.$dialogs.alert(this.$i18n.t('common.alert.send-success'));
      } catch (error) {
        console.log('error', error);
      }
    },
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('service.management.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.management.leftPanel.table-column1'),
          value: 'srvcId',
          width: '28%',
        },
        {
          text: this.$i18n.t('service.management.leftPanel.table-column2'),
          value: 'srvcNm',
          width: '28%',
        },
        {
          text: this.$i18n.t('service.management.leftPanel.table-column3'),
          value: 'srvcSttus',
          width: '11%',
        },
        {
          text: this.$i18n.t('service.management.leftPanel.table-column4'),
          value: 'createDate',
          width: '28%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.management.rightPanel.title');
    },
    tabItems() {
      return [
        {
          id: 'serviceInfo',
          title: this.$i18n.t('service.management.rightPanel.tab1.title'),
          headers: [
            {
              text: this.$i18n.t('service.management.rightPanel.tab1.header1'),
              width: '45%',
            },
            {
              text: this.$i18n.t('service.management.rightPanel.tab1.header2'),
              width: '55%',
            },
          ],
          isBottomBtn: true,
          selectedItem: {
            srvcId: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label1'),
              value: '',
            },
            clientSecret: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label2'),
              value: '',
            },
            srvcNm: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label3'),
              value: '',
            },
            webServerRedirectUri: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label4'),
              value: '',
            },
            mberRegistNtcnUrl: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label5'),
              value: '',
            },
            srvcConfmNtcnUrl: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label6'),
              value: '',
            },
            pushRedirectUrl: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label7'),
              value: '',
            },
            feedRedirectUrl: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label8'),
              value: '',
            },
            cmpnyNm: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label9'),
              value: '',
            },
            chargerTelno: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label10'),
              value: '',
              isCustom: true,
            },
            chargerEmail: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label11'),
              value: '',
            },
            dc: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label12'),
              value: '',
            },
            grantTypes: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label13'),
              value: [],
              isCustom: true,
            },
            createDate: {
              name: this.$i18n.t('service.management.rightPanel.tab1.label14'),
              value: '',
              isCustom: true,
            },
          },
        },
        {
          title: this.$i18n.t('service.management.rightPanel.tab2.title'),
          height: '676',
          headers: [
            {
              text: this.$i18n.t('service.management.rightPanel.tab2.header1'),
            },
            {
              text: this.$i18n.t('service.management.rightPanel.tab2.header2'),
            },
          ],
          selectedItem: [],
        },
        {
          title: this.$i18n.t('service.management.rightPanel.tab3.title'),
          height: '676',
          headers: [
            {
              text: this.$i18n.t('service.management.rightPanel.tab3.header1'),
            },
            {
              text: this.$i18n.t('service.management.rightPanel.tab3.header2'),
            },
          ],
          selectedItem: [],
        },
      ];
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdateStatus() {
      return this.$i18n.t('common.btn.update-status');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnUpdateSecret() {
      return this.$i18n.t('common.btn.update-secret');
    },
    btnUpdateAuthType() {
      return this.$i18n.t('common.btn.update-authType');
    },
    btnSendEmail() {
      return this.$i18n.t('common.btn.send-email');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.getSrvcSttusCodes();
    },
  },
};
</script>

<style scoped>
.bt-line24 {
  margin: 1.5px;
}
</style>
